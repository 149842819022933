<template>
  <div class="drawing winStyle" id="draw">
    <div id="targetWin" v-show="target == 1">
      <targetWin></targetWin>
    </div>
    <div class="main box box_column">
      <!-- <div class="top box justify center" style="visibility: hidden;">  
        <div>测点查看（三视图）</div>
        <div @click="getWin" class="pointer">
          <img src="../../../assets/images/img/set/shouqi_icon.png" alt="" />
        </div>
      </div> -->
      <div class="bottom box" v-if="win == 1">
        <div class="left box box_column">
          <div class="left1 center box around" style="border-radius: 0px">
            <!-- 立面图 定位点 -->
            <div class="fuBox box around">
              <div class="bgImg around box">
                <!-- 界面点 -->
                <div class="pointer" v-if="freeze == 1" id="kuai1"></div>
                <div
                  v-for="(item, index) in ceYuanList"
                  :key="index"
                  class="box around center yuan"
                  :class="
                    addTargetList.includes(item) == true ? 'selectBg' : ''
                  "
                  :style="{ top: item.y + '%', left: item.x1 + '%' }"
                >
                  <span
                    @click="go(item)"
                    class="iconfont"
                    :class="item.icon"
                    v-on:mouseover="mouseenter($event, item)"
                    v-on:mouseout="mouseleave(item)"
                  ></span>
                </div>
                <div class="box around" style="width: 100%" v-html="src1"></div>
              </div>
            </div>
            <div class="title">立面图</div>
          </div>
          <div class="left2 box center around" style="border-radius: 0px">
            <!-- 平面图 定位点 -->
            <div class="fuBox box around">
              <div class="bgImg around box">
                <!-- :class="addTargetList(item.id) == true?'selectBg':''" -->
                <div
                  :class="
                    addTargetList.includes(item) == true ? 'selectBg' : ''
                  "
                  class="box around center yuan"
                  v-for="(item, index) in ceYuanList"
                  :key="index"
                  :style="{ top: item.x + '%', left: item.x1 + '%' }"
                >
                  <span
                    @click="go(item)"
                    class="iconfont"
                    :class="item.icon"
                  ></span>
                </div>
                <!-- <div style="width: 100%" class="box" v-html="src2"></div> -->
                <div style="width: 100%" class="box around" v-html="src2"></div>
              </div>
            </div>
            <div class="title">平面图</div>
          </div>
        </div>
        <div class="right box around" style="border-radius: 0px">
          <!-- <div class="title1">{{name}}</div> -->
          <div class="title">剖面图</div>
          <div class="input box center justify">
            <div class="pointer" @click="getLeft">
              <span class="iconfont icon-arrow_left"></span>
            </div>
            <!--width:50px  elli-->
            <div class="textC" style="">{{ name }}</div>
            <div class="pointer" @click="getRight">
              <span class="iconfont icon-arrow_right"></span>
            </div>
          </div>
          <!-- 侧视图 定位点 -->
          <div id="moveDiv" class="fuBox1 box around">
            <div
              id="wheel-img"
              class="bgImg1 box around"
              @mousewheel.prevent="scaleFun"
              @mousedown.prevent="draggableFun($event)"
              :style="scaleImg"
            >
              <!-- :class="addTargetList.includes(item) == true?'selectBg':''"  @click="go(item)"  -->
              <div
                v-for="(item, index) in ceYuan"
                :class="addList.includes(item.id) == true ? 'selectBg' : ''"
                class="box center around yuan scale-change"
                :key="index"
                :style="{
                  top: item.y - 0.8 + '%',
                  left: item.x - 1.5 + '%',
                  transform:
                    addList.includes(item.id) == true
                      ? `scale(${bigValue})`
                      : `scale(${pointValue})`,
                }"
              >
                <span
                  @click="go1(item)"
                  class="iconfont"
                  :class="item.icon"
                ></span>
              </div>
              <div style="width: 100%" class="box around" v-html="src3"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom box" v-if="win == 0"></div>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import targetWin from "../../../components/alone/targetWin";
import { mapState } from "vuex";
export default {
  name: "drawing",
  components: {
    targetWin,
  },
  data() {
    return {
      //   projectId: "",
      currentItem: [],
      freeze: 1,

      src1: "",
      src2: "",
      src3: "",
      genre: "1", //1传感器   2 摄像头
      list: [], //截面数组

      arrindex: 0, //左右 + 1
      ceId: "", //侧面图id
      arr: [], //所有截面
      ce: "", //截面变化

      name: "",

      ceYuan: [],
      src: "",

      cedianArr: [], //接收传过来的测点
      win: "1",

      ceList: [], //截面数组
      ceYuanList: [], //测点坐标数据

      target: "",
      addTargetList: [], //通过选中显示图表
      addList: [], // 获取选中的id数组， 上面的数组抽取id
      targetOne: "", //选中第一个测试

      getOn: "0", //是否开启多个测点展示0 不 ，1是可以

      inputPercentValue: 100, // 默认放大/缩小比例
      pointValue: "100", // 测点缩放比例
      bigValue: "100",

      divTop: 0,
      divLeft: 0,

      flag: false, // 缩放标识
    };
  },
  //创建
  created() {
    this.pointValue = 100;
    this.bigValue = this.pointValue + 5;
    this.projectId = sessionStorage.getItem("projectId");
    this.$bus.$on("wholeArr", this.wholeArr); //获取类型
    this.$bus.$on("addTarget", this.addTarget); //开关是否可以追加
    // this.$bus.$on("changeNodeClick", this.changeNodeClick); //开关是否可以追加
    // changeNodeClick1
    this.$bus.$on("changeNodeClick", this.changeNodeClick); // 定位
  },
  computed: {
    scaleImg() {
      return {
        transform: `scale(${this.inputPercentValue + "%"})`,
        transformOrigin: "50% 50%",
        top: this.divTop + "%",
        left: this.divLeft + "%",
      };
    },
    ...mapState(["projectId"]),
    // ...mapState({
    //   // projectId(state) {
    //   //   console.log("state.projectId", state.projectId);
    //   //   return state.projectId;
    //   // }

    // })
  },
  //安装
  mounted() {
    this.imgData();
    this.pointerData();

    setTimeout(() => {
      console.log("list", this.list);

      this.arr = this.list;
      this.name = this.list[0].name;
      this.ceId = this.list[0].id; //截面id
      //   console.log('name, ceid', this.name, this.ceId);  //获取第一个截面 第一个截面名称

      this.selectData();

      if (!this.arr[0].axleValueX) {
        this.divLeft = 0;
      } else {
        this.divLeft = this.arr[0].axleValueX;
      }

      if (!this.arr[0].axleValueY) {
        this.divTop = 0;
      } else {
        this.divTop = this.arr[0].axleValueY;
      }

      if (!this.arr[0].proportion) {
        this.inputPercentValue = 100;
      } else {
        this.inputPercentValue = this.arr[0].proportion;
      }
      this.displayScaleFun();
    }, 200);
  },

  //方法
  methods: {
    //  数据里选出列表传过来的数组

    // 测点列表传值

    changeNodeClick(item) {
      
      // if (item.length == 1) {
      //   this.getOn = 0;
      // }

      // this.$bus.$emit("clickTab", { id: 3 }); //点击列表关闭多选测点

      this.currentItem = [];
      let n = this.ceYuanList.length;
      let flag = false;
      for (let i = 0; i < n; i++) {
        if (this.ceYuanList[i].targetId == item.treeTargetId) {
          // this.addTargetList.push(this.ceYuanList[i]);
          this.go(this.ceYuanList[i]);
          flag = true;
          if (this.win == 0) {
            return;
          }
          return;
        }
      }
      if (!flag) {
        this.go(item);
      }
    },

    // 滚轮 放大/缩小
    scaleFun(e) {
      this.flag = true;
      let direction = e.deltaY > 0 ? "down" : "up";
      if (direction === "up") {
        // 滑轮向上滚动
        this.inputPercentValue += 10;
      } else {
        // 滑轮向下滚动
        if (this.inputPercentValue > 10) {
          this.inputPercentValue -= 10;
        }
      }
      let ele = document.getElementsByClassName("scale-change");
      let num = (100 / this.inputPercentValue).toFixed(3) * 100 + "%";
      for (let i = 0; i < ele.length; i++) {
        ele[i].style.transform = `scale(${num})`;
      }
    },
    // 函数 默认显示放大/缩小
    displayScaleFun() {
      // 整个剖面图
      let ele = document.getElementById("wheel-img");
      let num = this.inputPercentValue + "%";
      console.log("缩放比例", num);
      ele.style.transform = `scale(${num})`;
      // 测点
      this.pointValue = (100 / this.inputPercentValue).toFixed(3) * 100 + "%";
      this.bigValue = (100 / this.inputPercentValue).toFixed(3) * 100 + 5 + "%";
    },
    // 鼠标移入
    mouseenter(e, item) {
      this.target = 1;
      console.log("e", e.clientX);
      console.log("item", item);
      this.$bus.$emit("targetHover", item.targetId);
      var dom = document.getElementById("targetWin");
      dom.style.left = e.clientX - 220 + "px";
      dom.style.top = e.clientY + 40 + "px";
      //   this.target = item.id;
      console.log("this.target", this.target);
      //  cedianArr
      // this.imgSrc3(item.viewsId);   //触摸更新刨面图背景图
      // this.ceId = item.viewsId;     //获取刨面图 id
      // this.numberData(this.cedianArr);  //画点
    },
    //  鼠标移出
    mouseleave(itme) {
      this.target = "0";
    },

    //  隐藏显示窗口
    getWin() {
      console.log("三视图显示隐藏", this.currentItem);
      if (this.win == 1) {
        var dom = document.getElementById("draw");
        dom.style.height = "0%";
        this.win = 0;
      } else {
        var dom = document.getElementById("draw");
        dom.style.height = "732px";
        this.win = 1;
        console.log("三视图显示", this.currentItem);
        if (this.currentItem.length) {
          this.$nextTick(() => {
            this.go(this.currentItem[0], true);
          });
        } else {
          console.log("执行这个");
          this.$nextTick(() => {
            this.go([], true);
          });
        }
      }
    },

    ceshow() {},

    // 左右
    getLeft() {
      this.flag = false;
      console.log("cedianArr", this.cedianArr);

      if (this.arrindex == 0) {
        return;
      }
      this.arrindex = this.arrindex - 1;
      var item = this.arr[this.arrindex];
      console.log("item", item);
      let ele = document.getElementById("wheel-img");
      ele.style.left = item.axleValueX + "%";
      ele.style.top = item.axleValueY + "%";

      if (!item.proportion) {
        this.inputPercentValue = 100;
      } else {
        this.inputPercentValue = item.proportion;
      }
      this.displayScaleFun();
      let div = document.getElementById("wheel-img");
      div.style.left = (item.axleValueX || 0) + "%";
      div.style.top = (item.axleValueY || 0) + "%";

      this.name = item.name;
      this.imgSrc3(item.id);
      this.ceId = item.id;
      this.numberData(this.cedianArr);
    },
    // 左右
    getRight() {
      this.flag = false;
      console.log("arrindex", this.arrindex, this.arr); //截面的index和整个的数组

      if (this.arrindex == this.arr.length - 1) {
        return;
      }
      this.arrindex = this.arrindex + 1;
      var item = this.arr[this.arrindex];
      console.log("item", item);
      let ele = document.getElementById("wheel-img");
      ele.style.left = item.axleValueX + "%";
      ele.style.top = item.axleValueY + "%";

      if (!item.proportion) {
        this.inputPercentValue = 100;
      } else {
        this.inputPercentValue = item.proportion;
      }
      this.displayScaleFun();
      let div = document.getElementById("wheel-img");
      div.style.left = (item.axleValueX || 0) + "%";
      div.style.top = (item.axleValueY || 0) + "%";

      this.name = item.name;
      this.imgSrc3(item.id);
      this.ceId = item.id;
      this.numberData(this.cedianArr);
    },

    // bus on 获取的值
    wholeArr(e) {
      console.log("e 选择的类型组", e);
      this.numberData(e);
      this.cedianArr = e;
      this.numData(); //正，俯的全部点
    },

    //bus on  可以添加多个测点进行数据展示
    addTarget(e) {
      this.getOn = e; //开启
      if (this.getOn == 0) {
        this.addTargetList.splice(1);
        this.go(this.addTargetList[0]);
      }
    },

    //获取点位
    go(item, flag = false) {
      this.currentItem = [];
      if (this.getOn == 1) {
        for (var i = 0; i < this.addTargetList.length; i++) {
          if ((this.addTargetList[i].targetId ? this.addTargetList[i].targetId: this.addTargetList[i].treeTargetId) == (item.targetId || item.treeTargetId)) {
            if (this.addTargetList.length == 1) {
              return;
            } else {
              this.addTargetList.splice(i, 1);
              this.addList.splice(i, 1);
            }
            if (!flag) {
              this.$bus.$emit("targetArr", this.addTargetList); //发给图表
              this.$bus.$emit("myClick", this.addTargetList); //发给图表
            }
            return;
          } else {
            this.addList.push(this.addTargetList[i].id);
          }
        }
        // console.log('addList', this.addList);

        if (this.addTargetList.length == 9) {
          this.$message.error("最多选择9个测点");
          // this.$bus.$emit("targetArr", this.addTargetList); //发给图表
          this.$bus.$emit("myClick", this.addTargetList); //发给图表
          return;
        }
        // console.log('this.addTargetList.', this.addTargetList.length);
        // 0722
        // if (this.addTargetList.length > 0) {
        //   if (this.addTargetList[0].type != item.type) {
        //     this.$message.error("请选择同一类型设备");
        //     return; //如果选择多个类型， 就此停止
        //   }
        // }
        if (
          this.addTargetList.length > 0 &&
          (this.addTargetList[0].type || this.addTargetList[0].targetType) != (item.type || item.targetType)
        ) {
          if (this.addTargetList.length == 1) {
            this.addTargetList = [];
          } else {
            this.$message.error("请选择同一类型设备");
            // this.$bus.$emit("targetArr", this.addTargetList); //发给图表
            this.$bus.$emit("myClick", this.addTargetList); //发给图表
            return; //如果选择多个类型， 就此停止
          }
        }
        var el = document.getElementById("kuai1");
        if (Array.isArray(item) && item == false) {
          el.style.display = "none";
        } else {
          el.style.display = "block";
        }
        if (item.x1) {
          el.style.left = item.x1 + "%"; //x轴移动
        }
        if (item.viewsId) {
          this.imgSrc3(item.viewsId); //点击测点更新截面图
        }
        this.addTargetList.push(item); //开启就是数组形式

        // 截面的选择 和 定位
        this.arr.forEach((item1, index) => {
          if (item1.id == this.addTargetList[0].viewsId) {
            this.name = item1.name;
            this.arrindex = index;
          }
        });
      } else {
        // 截面的选择 和 定位  所有界面 item是所选择的对象{'id',coce,....}

        var el = document.getElementById("kuai1");
        if (Array.isArray(item) && item == false) {
          el.style.display = "none";
        } else {
          el.style.display = "block";
        }
        if (item.x1) {
          el.style.left = item.x1 + "%"; //x轴移动
        }
        if (item.viewsId) {
          this.imgSrc3(item.viewsId); //点击测点更新截面图
        }

        this.arr.forEach((item1, index) => {
          if (item1.id == item.viewsId) {
            this.name = item1.name;
            this.arrindex = index;
            if (item1.proportion) {
              this.inputPercentValue = item1.proportion;
            } else {
              this.inputPercentValue = 100;
            }

            let div = document.getElementById("wheel-img");
            div.style.left = (item1.axleValueX || 0) + "%";
            div.style.top = (item1.axleValueY || 0) + "%";
          }
        });
        this.displayScaleFun();
        this.addTargetList = [];
        this.addTargetList.push(item); //不开启，就是一个
        this.currentItem.push(JSON.parse(JSON.stringify(item)));
      }
      if (!flag) {
        this.$bus.$emit("targetArr", this.addTargetList); //发给图表
        this.$bus.$emit("myClick", this.addTargetList); //发给图表
      }

      // this.$bus.$emit("targetArr", this.addTargetList); //发给图表

      // this.imgSrc3(item.viewsId);   //触摸更新刨面图背景图
      this.ceId = item.viewsId; //获取刨面图 id

      this.numberData(this.cedianArr); //画点
    },
    go1(item) {
      // this.addTargetList = [item]
      var el = document.getElementById("kuai1");
      el.style.left = item.x1 + "%"; //x轴移动
      this.imgSrc3(item.viewsId); //点击测点更新截面图

      if (this.getOn == 1) {
        var isReturn =false
        if (this.addTargetList.length > 1) {
          for (var i = 0; i < this.addTargetList.length; i++) {
            if (this.addTargetList[i].id == item.id) {
              this.addTargetList.splice(i, 1);
              this.addList.splice(i, 1);
              this.$bus.$emit("targetArr", this.addTargetList); //发给图表
              this.$bus.$emit("myClick", this.addTargetList);
              isReturn = true
            }
          }
        }
        if (this.addTargetList.length == 1 && this.addTargetList[0].id == item.id) {
          return
        }
        if(isReturn)return;
        if (this.addTargetList.length == 9) {
          this.$message.error("最多选择9个测点");
          return;
        }
        if (
          this.addTargetList.length > 0 &&
          this.addTargetList[0].type == item.type
        ) {
          this.addTargetList.push(item);
        } else {
          this.addTargetList = [];
          this.addTargetList.push(item);
        }

        // 截面的选择 和 定位
        this.arr.forEach((item1, index) => {
          if (item1.id == this.addTargetList[0].viewsId) {
            this.name = item1.name;
            this.arrindex = index;
          }
        });
        this.$bus.$emit("targetArr", this.addTargetList); //发给图表
        this.$bus.$emit("myClick", this.addTargetList);
        // this.imgSrc3(item.viewsId);   //触摸更新刨面图背景图
        this.ceId = item.viewsId; //获取刨面图 id
        console.log("cedianArr", this.cedianArr); // 监测类型数组

        this.numberData(this.cedianArr); //画点
        return;

        // this.addTargetList.push(item); //开启就是数组形式
        // // console.log('addList', this.addList);

        // console.log('疯了疯了疯了疯了疯了疯了疯了----addTargetList', this.addTargetList);
        // if (this.addTargetList.length == 9) {
        //   this.$message.error("最多选择9个测点");
        //   return;
        // }

        // if (this.addTargetList.length > 0 && this.addTargetList[0].type != item.type) {
        //   if (this.addTargetList.length == 1) {
        //     this.addTargetList = [];
        //   } else {
        //     this.$message.error("请选择同一类型设备");
        //     return; //如果选择多个类型， 就此停止
        //   }
        // }

        // // 截面的选择 和 定位
        // this.arr.forEach((item1, index) => {
        //   if (item1.id == this.addTargetList[0].viewsId) {
        //     this.name = item1.name;
        //     this.arrindex = index;
        //   }
        // });
      } else {
        // 截面的选择 和 定位  所有界面 item是所选择的对象{'id',coce,....}
        console.log("this.arr", this.arr);

        this.arr.forEach((item1, index) => {
          if (item1.id == item.viewsId) {
            this.name = item1.name;
            this.arrindex = index;
            // console.log('标识llllllllllllllllllllllllllllllllkkkkkkkkkkkkkkkkkkkkkkkkk',this.flag)
            // 如果在当前剖面图缩放，点击测点缩放比例不变
            if (!this.flag) {
              if (item1.proportion) {
                this.inputPercentValue = item1.proportion;
              } else {
                this.inputPercentValue = 100;
              }

              let div = document.getElementById("wheel-img");
              div.style.left = (item1.axleValueX || 0) + "%";
              div.style.top = (item1.axleValueY || 0) + "%";
            }
          }
        });
        this.displayScaleFun();
        this.addTargetList = [];
        this.addTargetList.push(item); //不开启，就是一个
      }

      this.addTargetList = [];
      this.addTargetList.push(item);
      console.log(
        "啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊----单个的时候addTargetList",
        this.addTargetList
      );
      this.ceYuanList.forEach((eve) => {
        if (eve.id == this.addTargetList[0].id) {
          console.log(
            "有有有有有有有有有有-------",
            this.addTargetList.includes(item)
          );
        }
      });
      this.$nextTick(() => {
        this.$forceUpdate();
      });

      this.$bus.$emit("targetArr", this.addTargetList); //发给图表
      this.$bus.$emit("myClick", this.addTargetList);
      // this.imgSrc3(item.viewsId);   //触摸更新刨面图背景图
      this.ceId = item.viewsId; //获取刨面图 id
      console.log("cedianArr", this.cedianArr); // 监测类型数组

      this.numberData(this.cedianArr); //画点

      // console.log('addTargetList', this.addTargetList);
    },

    // 获取截面的x
    wholeX1() {
      this.$axios
        .get(`${this.baseURL}synthesis/select/views/section/${this.ceId}`)
        .then((res) => {
          console.log(
            "截面x的值",
            res.data.data,
            "addTargetList",
            this.addTargetList
          );
          var number = res.data.data;

          var el = document.getElementById("kuai1");
          el.style.left = number.x1 + "%"; //x轴移动

          arr.forEach((item, index) => {
            item.x1 = number.x;
          });
          this.ceYuan = arr;
          // console.log('ceYuan', this.ceYuan);
        })
        .catch((err) => {});
    },

    //   ------------------------请求数据-------------------------------------------------------
    // 通过截面， 类型， 查参数  对应接口   --- 三视图测点选择
    numberData(arr) {
      if (arr.length == 0) {
        arr = [0];
      }
      var data = {
        typeIds: arr.toString(), //字符串类型组
        targetId: "", //指定测点id
        viewsId: this.ceId, //截面id
        genre: "", // 1传感器   2摄像头
      };
      console.log("data", data);
      console.log("111", this.ceId);
      console.log("arr", arr);

      var path = `typeIds=${data.typeIds}&targetId=${data.targetId}&viewsId=${data.viewsId}&genre=${data.genre}`;
      console.log("path", path);
      this.$axios
        .get(`${this.baseURL}synthesis/views/target/${this.projectId}?${path}`)
        .then((res) => {
          console.log("获取截面上的测点xy", res.data.data);
          var arr = res.data.data;
          this.wholeX(arr);
        })
        .catch((err) => {});
    },

    // 获取截面的x
    wholeX(arr) {
      this.$axios
        .get(`${this.baseURL}synthesis/select/views/section/${this.ceId}`)
        .then((res) => {
          console.log("截面x的值", res.data.data);
          var number = res.data.data;

          var el = document.getElementById("kuai1");
          el.style.left = number.x + "%"; //x轴移动

          arr.forEach((item, index) => {
            item.x1 = number.x;
            item.scaleChange = 1;
          });
          console.log("ceYuan", arr, "addTargetList", this.addTargetList);
          // this.ceYuan = [];
          this.ceYuan = arr; //截面的点
          this.addList = [];
          this.addTargetList.forEach((item, index) => {
            this.addList.push(item.id);
          });

          // this.targetOne = this.addList[0]
          // console.log('this.addList[0]', this.addList[0]);
        })
        .catch((err) => {});
    },

    // 正，俯，类型所有的点
    numData() {
      if (this.cedianArr.length == 0) {
        this.cedianArr = [0];
      }
      this.ceYuanList = [];
      var arr = this.list;

      // 遍历截面 arr所有的截面 1-1  每个截面去请求，获取所有测点
      arr.forEach((item, index) => {
        this.xyData(item.id, index);
      });
      // this.selectData();
    },
    xyData(ceid, indx) {
      // console.log("cedianArr", this.cedianArr);

      var data = {
        typeIds: this.cedianArr.toString(), //字符串类型组
        targetId: "", //指定测点id
        viewsId: ceid, //截面id
        genre: "", // 1传感器   2摄像头
      };
      // console.log('data', data);
      var path = `typeIds=${data.typeIds}&targetId=${data.targetId}&viewsId=${data.viewsId}&genre=${data.genre}`;
      this.$axios
        .get(`${this.baseURL}synthesis/views/target/${this.projectId}?${path}`)
        .then((res) => {
          // console.log("获取截面上的测点xy, index", indx, ceid, res.data.data, res);
          if (indx == 0) {
            this.targetOne = res.data.data[0].id;
          }
          var arr = res.data.data;
          this.x1Data(arr, ceid, indx);
        })
        .catch((err) => {});
    },
    x1Data(arr, ceid, indx) {
      this.$axios
        .get(`${this.baseURL}synthesis/select/views/section/${ceid}`)
        .then((res) => {
          // console.log("截面x的值", res.data.data);
          var number = res.data.data;
          arr.forEach((item, index) => {
            item.x1 = number.x;
            //  item.y = item.y-20
            //  item.x =  item.x-20
          });
          this.ceYuanList = this.ceYuanList.concat(arr); // 所有的测点
          // console.log("list", this.list.length, "indx", indx);
          if (this.list.length === indx + 1) {
            // console.log("list", this.list.length, "indx", indx);
            this.selectData();
          }
          // this.ceYuan = arr;
          // console.log("ceYuanList", this.ceYuanList);
        })
        .catch((err) => {});
    },

    selectData() {
      //镇海桥 默认读取的测点
      // if(this.projectId == '10009'){
      //     console.log('如果是黄山默认显示的测点');
      //     var item = {code: 'JGWY-G1', genre: 1,icon: 'icon-pianyi_grey_icon',id: 127, projectId: 10009,targetId: 266, type: 1007, unit: 'mm', viewsId: '149', x: '45.550847', x1:'74.693252', y:0 };
      //     this.ceId = '149';   //获取截面id

      //     this.arr.forEach((item1, index)=>{
      //         console.log('item1', item1.id, this.ceId);
      //         if(item1.id == this.ceId){
      //             this.name = item1.name;
      //             this.arrindex = index;
      //         }
      //     })

      //     setTimeout(()=>{
      //         console.log('ceYuanList-ceYuanList-ceYuanList-ceYuanList', this.ceYuanList);
      //         this.ceYuanList.forEach((item, index)=>{
      //             if(item.targetId == '266'){
      //                 console.log('item266 266 266 266-----', item);
      //                 this.addTargetList = [];
      //                 this.addTargetList.push(item)
      //             }
      //         })
      //         // this.ceYuan = this.addTargetList
      //         console.log('1',this.addTargetList)
      //         console.log('2', this.ceYuan)

      //         this.$bus.$emit('targetArr',  [item]);   //发给图表
      //     },1300)
      // }

      setTimeout(() => {
        console.log("ceYuanList", this.ceYuanList);
        console.log("targetOne", this.targetOne);

        this.ceYuanList.forEach((item, index) => {
          if (item.id == this.targetOne) {
            console.log("查看运行次数", item, "targetone", this.targetOne);
            this.addTargetList = [];
            this.addTargetList.push(item);
            this.$bus.$emit("targetArr", [item]); //发给图表

            var el = document.getElementById("kuai1");
            el.style.left = item.x1 + "%"; //x轴移动
          }
        });
        this.addTargetList.forEach((item, index) => {
          this.addList.push(item.id);
        });
        // console.log('6666', this.addTargetList);
      }, 700);
    },

    //  获取三视图图片
    imgData() {
      this.$axios
        .get(`${this.baseURL}synthesis/views/list/${this.projectId}`)
        .then((res) => {
          console.log("获取三视图", res.data.data);
          var data = res.data.data;
          data.forEach((item, index) => {
            if (item.typeId == 1) {
              if (item.list != null) {
                this.imgSrc1(item.list[0].id);
              }
            }
            if (item.typeId == 2) {
              if (item.list != null) {
                this.imgSrc2(item.list[0].id);
              }
            }
            if (item.typeId == 3) {
              if (item.list != null) {
                this.imgSrc3(item.list[0].id);
                this.list = item.list; // 所有的截面
              }
            }
          });
        })
        .catch((err) => {});
    },

    imgSrc1(id) {
      this.$axios
        .get(`${this.baseURL}synthesis/img/${id}`)
        .then((res) => {
          console.log("res.data-src1", res.data);

          this.src1 = res.data;
        })
        .catch((err) => {});
    },
    imgSrc2(id) {
      this.$axios
        .get(`${this.baseURL}synthesis/img/${id}`)
        .then((res) => {
          console.log("res.data-src1", res.data);

          this.src2 = res.data;
        })
        .catch((err) => {});
    },
    imgSrc3(id) {
      this.$axios
        .get(`${this.baseURL}synthesis/img/${id}`)
        .then((res) => {
          this.src3 = res.data;
        })
        .catch((err) => {});
    },
    // 鼠标拖拽
    draggableFun(e) {
      console.log("e---------------------------", e);
      let div = document.getElementById("moveDiv");
      let dragFlag = false;
      let x, y;

      div.onmousedown = function (e) {
        dragFlag = true;
        e = e || window.event;
        // 获取鼠标在元素上的位置（鼠标按下时在元素上得位置）
        x = e.clientX - div.offsetLeft + 60;
        y = e.clientY - div.offsetTop;
        console.log("鼠标按下时在元素上的位置----------------", x, y);
      };

      div.onmousemove = function (e) {
        if (dragFlag) {
          e = e || window.event;
          div.style.left = e.clientX - x + "px";
          div.style.top = e.clientY - y + "px";
          console.log(
            "鼠标拖动过程中元素的left和top对应的px-------------------",
            e.clientX - x,
            e.clientY - y
          );
        }
      };
      // 鼠标抬起事件
      div.onmouseup = function (e) {
        dragFlag = false;
      };
    },

    // 读取测点位置
    pointerData() {
      var data = {
        projectId: this.projectId,
        typeIds: "", //类型
        targetId: "",
        viewsId: "",
        genre: this.genre,
      };

      this.$axios
        .get(
          `${this.baseURL}synthesis/views/target/${data.projectId}?typeIds=${data.typeIds}&targetId=${data.targetId}&viewsId=${data.viewsId}&genre=${data.genre}`
        )
        .then((res) => {
          console.log("获取测点位置", res);
        })
        .catch((err) => {});
    },
  },
};
</script>
<style scoped lang="scss">
.drawing {
  width: 100%;
  height: 620px;
  margin-top: 18px;
}

.main {
  width: 100%;
  height: 100%;
}

.bottom {
  height: 100%;
  padding: 0 !important;
  overflow: hidden;
  padding: 20px;
}

.bgImg {
  // width: 100%;
  // height: 100%;
  position: relative;
  // border: 1px solid #ccc;
}

.left {
  width: 70%;
  // height: 100%;
  // margin-right: 8px;
  border-right: 1px solid #0C3380;

  .left1 {
    // padding: 0 60px;
    height: 60%;
    // background: rgba($color: #ccc, $alpha: 0.1);
    // background: url('../../../assets/images/img/zhengshi_bg.png');
    // background: #102048;
    border-radius: 28px;
    // background: linear-gradient(to top, #102048, #17356f);
    background-size: 100% 100%;
    margin-bottom: 16px;
    overflow: hidden;
    position: relative;
    border-bottom: 1px solid #0C3380;
    .fuBox {
      position: relative;
      width: 100%;
      height: 100%; // 22
      // border: 1px solid red;
    }

    .title {
      position: absolute;
      top: 32px;
      left: 32px;
      width: 80px;
      text-align: center;
      line-height: 32px;
      height: 32px;
      background: rgba(0, 7, 23, 0.6);
      border-radius: 8px;
      // bottom: 30px;
    }
  }

  .left2 {
    // padding: 0 60px;
    height: 40%;
    // background: rgba($color: #ccc, $alpha: 0.1);
    // background: url('../../../assets/images/img/zhengshi_bg.png');
    // background: #102048;
    border-radius: 28px;
    // background: linear-gradient(to top, #102048, #17356f);
    background-size: 100% 100%;
    overflow: hidden;
    position: relative;
    padding: 0 20px;

    .fuBox {
      position: relative;
      width: 100%;
      height: 100%; // 90   //100%
      // border: 1px solid red;
    }

    .title {
      position: absolute;
      top: 32px;
      left: 32px;
      width: 80px;
      text-align: center;
      line-height: 32px;
      height: 32px;
      background: rgba(0, 7, 23, 0.6);
      border-radius: 8px;
    }
  }
}

.right {
  overflow: hidden;
  padding-left: 60px;
  padding-right: 60px;
  width: 30%;
  // background: url('../../../assets/images/img/poumian_bg.png');
  // background: #102048;
  border-radius: 28px;
  // background: linear-gradient(to top, #102048, #17356f);
  background-size: 100% 100%;
  position: relative;
  // height: 100%;

  .title {
    position: absolute;
    top: 32px;
    left: 32px;
    width: 80px;
    text-align: center;
    line-height: 32px;
    height: 32px;
    background: rgba(0, 7, 23, 0.6);
    border-radius: 8px;
  }

  .title1 {
    position: absolute;
    width: 100%;
    bottom: 228px;
    text-align: center;
  }

  .input {
    position: absolute;
    bottom: 32px;
    width: 140px;
    text-align: center;
    left: 50%;
    margin-left: -70px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #8a94a6;
    background: rgba(0, 7, 23, 0.6);
    padding: 0 20px;
    z-index: 2;
  }

  .fuBox1 {
    position: relative;
    width: 100%;
    height: 100%;
    // margin-top: 40%;
    // overflow: hidden;
    // border: 1px solid red;
  }

  .bgImg1 {
    // width: 100%;
    // height: 100%;
    position: relative;
    // border: 1px solid #ccc;
  }

  svg {
    border-color: #ccc !important;
  }
}

.yuan {
  position: absolute;
  z-index: 200;
  width: 30px;
  height: 30px;
  background: url("../../../assets/images/img/set/hover-cedian_light1.png");
  background-size: 100% 100%;
  border-radius: 20px;

  margin-left: -10px;
  margin-top: -10px;

  span {
    font-size: 18px;
    transform: scale(0.5);
    text-align: center;
    margin-left: 2px;
  }
}

#targetWin {
  position: absolute;
  left: 30px;
  top: 30px;
  z-index: 220;
}

.selectBg {
  width: 30px !important;
  height: 30px !important;
  background: url("../../../assets/images/img/set/hover-cedian_light.png")
    no-repeat !important;
  background-size: 100% 100% !important;
  z-index: 999;
  /* transform: scale(0.28) !important; */
  // background: rosybrown;
  // border: 1px solid #ccc;
}

#kuai1 {
  position: absolute;
  /*定位*/
  z-index: 99;
  width: 8px;
  height: 100%;
  // background: blue;  /*设置一下背景*/
  background: url("../../../assets/images/img/set/zhengshi_xuxian 2.png");
  background-size: 100% 100%;
}

</style>
