<template>
  <!-- 综合监测 -->
  <div class="wholeIndex">
    <div class="main box" >
      <div class="flex1 left" >
        <div class="line1">
          <drawing></drawing>
        </div>
        <div class="line2" id="line2">
          <dataView></dataView>
        </div>
        <div class="line3">
          <tableList v-show="onoff == '0'"></tableList>
        </div>
      </div>
      <div class="right-icon box" style="position: relative;">
        <div class="right box around">
          <!-- 类型  typeWin-->
          <!-- <div class="typeWin winStyle" v-if="Win == 1"> -->
          <!-- <div class="typeWin winStyle" v-if="false">
            <div style="position: absolute; width: 80px; height: 30px; text-align: center; line-height: 30px; border-radius: 10px; border: 1px solid rgb(61, 107, 208); right: 10px; top: 10px" @click="selectAll()">{{ selectIsAll ? "全选" : "全不选" }}</div>
            <div v-for="(item, index) in aloneData" :key="index">
              <div class="top" v-if="item.list && item.list.length">{{ item.name }}</div>
              <div class="bottom box flexW" v-if="item.list && item.list.length">
                <div v-for="(item1, index1) in item.list" :key="index1" class="itemBox box box_column center around" @click="getType(item1)">
                  <div class="itemBg box around center pointer" :class="selectArr.includes(item1.id) == true ? 'activeColor' : ''">
                    <span class="iconfont" :class="item1.icon"></span>
                  </div>
                  <div class="fs10 typeName">{{ item1.name }}</div>
                </div>
              </div>
            </div>
          </div> -->

          <!-- 测点 -->
          <div class="pointerWin newTree" v-if="activeTab.includes('2') == true">
            <div class="fu">
              <el-input v-model="name" placeholder="请输入测点"></el-input>
              <div class="zi pointer" @click="bridgeTreeData">
                <img src="../../assets/images/all/sousuo.png" alt="" />
              </div>
            </div>
            <!-- :filter-node-method="filterNode" -->
            <el-tree ref="tree" :data="data" :default-expand-all="true" :props="defaultProps" node-key="treeTargetId" :current-node-key="currentNodeKey" :highlight-current="true" @node-click="handleNodeClick">
              <span class="custom-tree-node textL" slot-scope="{ node, data }">
                <span>
                  {{ data.treeName }}
                </span>
                <span v-if="data.treeTargetId == '0'">({{ data.count }})</span>
              </span>
            </el-tree>
          </div>
          <!-- <div class="typeWin" v-if="activeTab.includes('3') == true"></div> -->
          <div>
            <ul class="ul1">
              <li v-for="(item, index) in tabList" :key="index" class="pointer" @click="clickTab(item)" :class="activeTab.includes(item.id) == true ? 'tab1' : ''">
                <!-- <el-tooltip content="位置锁定" placement="left">
              <div class="btn box center around mB10 pointer" :class="{ tabActiveColor: lockItem.lock == 1 }" @click="saveData">
                <span class="iconfont icon-suoding_icon"></span>
              </div>
            </el-tooltip> -->
                <el-tooltip :content="item.title" placement="left">
                  <div class="iconfont yuan" :class="item.icon"></div>
                </el-tooltip>
              </li>
            </ul>
          </div>
        </div>
        <!--   v-if="Win == 1" -->
        <div class="flex1 icon-list" id="content-wrapper" v-if="activeTab.includes('1') == true && isShowDragList">
          <div class="icon-list-slide icon-list-shanghua"  @click="handlePrevious()">
            <span class="iconfont icon-shanghua transform180"></span>
          </div>
          <div class="icon-list-slide icon-list-xiahua"  @click="handleNext()">
            <span class="iconfont icon-xiahua"></span>
          </div>
          <div class="icon-list-item" id="list-item">
            <el-tooltip popper-class="right-tooltip" effect="dark" content="全选" placement="left">
              <div class="icon-list-all" @click="selectAll()"><span class="iconfont icon-quanxuan" :class="selectIsAll ? '' : 'icon-active'" style="font-size: 12px"></span></div>
            </el-tooltip>
            <draggable style="height: 100%;"  v-model="dragNameList" animation="300" :sort="false">
              <transition-group class="dragNameListFelx">
                <!-- <div v-for="(item, index) in aloneData" :key="index">
                  <div class="top" v-if="item.list && item.list.length">{{ item.name }}</div>
                  <div class="icon-list-line"></div>
                  <div class="bottom box flexW box_column" v-if="item.list && item.list.length">
                    <div v-for="(item1, index1) in item.list" :key="index1" class="itemBox center around" @click="getType(item1)">
                      <div class="itemBg box around center pointer" :class="selectArr.includes(item1.id) == true ? 'activeColor' : ''">
                        <span class="iconfont" :class="item1.icon"></span>
                      </div>
                      <div class="fs10 typeName">{{ item1.name }}</div>
                    </div>
                  </div>
                </div> -->
                <div v-for="(item, index) in dragNameList" :key="index">
                  <div class="top" v-if="item.list && item.list.length">{{ item.name }}</div>
                  <div class="icon-list-line" v-if="item.list && item.list.length"></div>
                  <div class="bottom box flexW box_column" v-if="!item.list || !item.list.length">
                    <div class="itemBox center around" @click="getType(item)">
                      <div class="itemBg box around center pointer" :class="selectArr.includes(item.id) == true ? 'activeColor' : ''">
                        <span class="iconfont" :class="item.icon"></span>
                      </div>
                      <div class="fs10 typeName">{{ item.name }}</div>
                    </div>
                  </div>
                </div>
              </transition-group>
            </draggable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawing from "./components/drawing";
import dataView from "./components/dataView";
import draggable from "vuedraggable";
import tableList from "./components/tableList.vue";
import mySwiperSlide from "../../components/swiper/swiperSlider";
export default {
  name: "wholeIndex",
  components: {
    drawing,
    dataView,
    tableList,
    draggable,
    mySwiperSlide
  },
  data() {
    return {
      isShowDragList: false,
      testflag: false,
      isDisabledIcon: false,
      selectIsAll: false,
      sanshituArr: [],
      projectId: "",
      tabList: [
        { id: "1", icon: "icon-cedianleixing_icon", title: "测点筛选" },
        { id: "2", icon: "icon-cedianxuanze_icon", title: "测点选择" },
        { id: "3", icon: "icon-duoxuan", title: "多选测点开关" }
      ],
      Win: "", //1,2,3
      name: "", //桥梁查询
      aloneData: [], //单桥类型数组

      //  测点二级树
      data: [],
      currentNodeKey: "",
      defaultProps: {
        children: "children",
        label: "treeName"
      },
      dragNameList: [],
      selectArr: [],

      onoff: "0", //开关
      isDisabledIcon: false,
      activeTab: ["1"],
      totalNum: 0,
      type: "" //切换环境，效应，荷载
    };
  },
  //创建
  created() {
    this.$bus.$emit("changeModel", "");
    // this.$bus.$on("targetArr", this.targetArr); //从三视图传过来的数组id
    this.$bus.$on("clickTab", this.clickTab); //点击列表关闭多选测点
    this.$bus.$on("myClick", this.targetArr);
    // this.pointerData();
    // setTimeout(() => {
    //   this.getHeight();
    // }, 800);
  },

  // watch: {
  //   name(val) {
  //     this.$refs.tree.filter(val)
  //   }
  // },

  //安装
  mounted() {
    this.type = this.$route.query.type;

    this.projectId = sessionStorage.getItem("projectId");

    this.typeData(); //所有类型选中
    //   this.$bus.$on('type', this.typeData)
  },

  //方法
  methods: {
    // 点击下一个
    handleNext() {
      console.log("shang", this.isDisabledIcon, this.dragNameList);
      // if (this.isDisabledIcon) {
      //   return;
      // }
      this.dragNameList.push(this.dragNameList[0]);
      this.dragNameList.splice(0, 1);
      console.log(this.dragNameList);
    },
    // 点击上一个
    handlePrevious() {
      console.log("xia", this.isDisabledIcon);
      // if (this.isDisabledIcon) {
      //   return;
      // }
      this.dragNameList.unshift(this.dragNameList[this.dragNameList.length - 1]);
      this.dragNameList.splice(this.dragNameList.length - 1, 1);
      // console.log(this.dragNameList)
    },
    // 计算高度
    getHeight() {
      let ele = document.getElementById("content-wrapper").offsetHeight;
      console.log("当前wrapper的总高度", ele);
      // let listLength = 100 * (this.dragNameList.length + 1) + 60;
      let x = 0,
        y = 0;

      this.dragNameList = [];
      this.aloneData.forEach((item) => {
        if (item.list && item.list.length) {
          x++;
          this.dragNameList.push(item);
          item.list.forEach((itm) => {
            this.dragNameList.push(itm);
            y++;

            // this.selectArr.push(itm.id);
          });
        }
      });

      let listLength = x * 38 + y * 64;
      console.log("结构树的高度", listLength);
      if (listLength < ele) {
        this.isDisabledIcon = true;
      } else {
        this.isDisabledIcon = false;
      }
    },
    slideItem(flag) {},
    formateSelectAll() {
      this.selectArr = [];
      this.aloneData.forEach((item) => {
        if (item.list && item.list.length) {
          item.list.forEach((itm) => {
            this.selectArr.push(itm.id);
          });
        }
      });
      // console.log("选中个数this.selectArr", this.selectArr);
      this.$bus.$emit("wholeArr", this.selectArr);
      this.pointerData(); //二级树
    },
    selectAll() {
      this.selectIsAll = !this.selectIsAll;
      this.selectArr.splice(0);
      if (!this.selectIsAll) {
        this.formateSelectAll();
        return;
      }
      this.$bus.$emit("wholeArr", this.selectArr);
      this.pointerData(); //二级树
    },
    // filterNode(value, data) {
    //   if (!value) return true;
    //   return data.name.indexOf(value) !== -1
    // },
    formateTreeSetFalse(data) {
      data.forEach((item) => {
        if (item.children && item.children.length) {
          this.formateTreeSetFalse(item.children);
        }
        if (item.treeTargetId) {
          let node = this.$refs.tree.getNode(item.treeTargetId);
          this.$set(node, "isCurrent", false);
        }
      });
    },
    formateTreeSelectStatus(data) {
      // let node = this.$refs.modelTree.getNode(item)
      // this.$set(node, 'isCurrent', true)
      // if (!tree.length || !data.length) return;
      if (!data || !data.length || !this.$refs.tree) {
        return;
      }
      this.formateTreeSetFalse(this.data);
      data.forEach((item) => {
        let node = this.$refs.tree.getNode(item.targetId || item.treeTargetId);
        this.$set(node, "isCurrent", true);
      });
    },
    targetArr(arr) {
      this.sanshituArr = arr;
      this.formateTreeSelectStatus(arr);
      // this.currentNodeKey = this.sanshituArr[0] ? this.sanshituArr[0].targetId : "";
      // if (this.$refs.tree) {
      //   this.$nextTick(() => {
      //     this.$refs.tree.setCurrentKey(this.currentNodeKey);
      //   });
      // }
    },
    //   选择类名
    getType(item) {
      console.log("类型选择", item);
      let that = this;
      // this.totalNum
      this.selectArr = Array.from(new Set(this.selectArr));
      if (this.selectArr.includes(item.id) == true) {
        this.selectArr.forEach((item1, index) => {
          if (item1 == item.id) {
            this.selectArr.splice(index, 1);

            this.selectIsAll = true;
            console.log("走这里", this.selectIsAll);
          }
        });
      } else {
        this.selectArr.push(item.id);
        console.log("走这里", this.selectArr.length, this.totalNum);

        if (this.selectArr.length === this.totalNum) {
          this.selectIsAll = false;
        }
      }
      this.$bus.$emit("wholeArr", this.selectArr);
      this.pointerData(); //二级树
      console.log("selectArr", this.selectArr);
    },

    // 树选中
    handleNodeClick(data) {
      if (data.treeTargetId != 0) {
        // this.loadingPage.close();
        this.$bus.$emit("changeNodeClick", data);
        // if (this.activeTab.includes("3")) {
        //   this.sanshituArr.push(data);
        //   this.$bus.$emit("changeNodeClick", this.sanshituArr);
        // } else {
        //   this.$bus.$emit("changeNodeClick", [data]);
        //   this.sanshituArr = [data];
        // }
      }
    },

    clickTab1() {
      if (this.Win == 1) {
        this.Win = 0;
      } else {
        console.log("弹框");
        this.Win = 1;
        this.typeData(); //类型
      }
    },
    clickTab2() {
      if (this.Win == 2) {
        this.Win = 0;
      } else {
        console.log("弹框");
        this.Win = 2;
        // this.typeData();   //类型
        this.pointerData();
        // this.currentNodeKey = this.sanshituArr[0] ? this.sanshituArr[0].targetId : ""
        // if (this.$refs.tree) {
        //   this.$nextTick(() => {
        //     this.$refs.tree.setCurrentKey(this.currentNodeKey);
        //   });
        // }
      }
    },
    clickTab(item) {
      // alert(item.id)
      if (this.activeTab.includes(item.id) != true) {
        // if (item.id == "1") {
        //   if (this.activeTab.includes("2")) {
        //     this.activeTab.forEach((obj, index) => {
        //       if ("2" == obj) {
        //         this.activeTab.splice(index, 1);
        //       }
        //     });
        //   }
        // }
        // if (item.id == "2") {
        //   if (this.activeTab.includes("1")) {
        //     this.activeTab.forEach((obj, index) => {
        //       if ("1" == obj) {
        //         this.activeTab.splice(index, 1);
        //       }
        //     });
        //   }
        // }
        // this.activeTab = [];
        this.activeTab.push(item.id);
      } else {
        this.activeTab.forEach((obj, index) => {
          if (item.id == obj) {
            this.activeTab.splice(index, 1);
          }
        });
      }

      console.log("this.activeTab", this.activeTab);

      //  类型选择
      if (item.id == 1) {
        // 测点筛选
        this.onoff = 0;
        // this.$bus.$emit("addTarget", "0");
        this.clickTab1();
      }
      // 测点
      if (item.id == 2) {
        //  测点选择
        // this.Win = 2
        this.onoff = 0;
        // this.$bus.$emit("addTarget", "0");
        this.clickTab2();
      }
      if (item.id == 3) {
        //  多选测点
        // this.Win = 0;
        // if (this.onoff == 1) {
        //   this.onoff = 0;
        //   this.$bus.$emit("addTarget", "0");
        // } else {
        //   this.onoff = 1;
        //   this.$bus.$emit("addTarget", "1");
        // }
        if (this.activeTab.includes("3")) {
          this.onoff = 1;
          this.$bus.$emit("addTarget", "1");
        } else {
          this.onoff = 0;
          this.$bus.$emit("addTarget", "0");
        }
      }
    },

    closeWin() {
      this.Win = "";
    },

    // 查询桥梁信息
    bridgeTreeData() {
      this.pointerData();
    },

    //   --------------------请求数据 ----------------------------
    // 类型
    typeData() {
      // synthesis/project/target/type/${this.projectId} synthesis/target/type
      //  this.$axios.get(`${this.baseURL}synthesis/target/type`).then(res => {
      this.$axios
        .get(`${this.baseURL}synthesis/project/target/type/${this.projectId}`)
        .then((res) => {
          console.log("类型22222222222", res.data.data);
          this.aloneData = res.data.data;
          // if (this.projectId != "10009") {
          //   this.aloneData.push({ id: "99", name: "安防监控", list: [{ id: "1", name: "视频监控", icon: "icon-shipinjiankong_white_icon1" }] });
          // }
          setTimeout(() => {
            if (this.type == 1) {
              this.aloneData.forEach((item, index) => {
                if (item.id == 1) {
                  item.list.forEach((item1, index1) => {
                    this.selectArr.push(item1.id);
                  });
                }
              });
              this.$bus.$emit("wholeArr", this.selectArr);
            } else if (this.type == 2) {

              this.aloneData.forEach((item, index) => {
                if (item.id == 2) {
                  item.list.forEach((item1, index1) => {
                    this.selectArr.push(item1.id);
                  });
                }
              });
              this.$bus.$emit("wholeArr", this.selectArr);
            } else if (this.type == 3) {

              this.aloneData.forEach((item, index) => {
                if (item.id == 3) {
                  if (item.list.length > 0) {
                    item.list.forEach((item1, index1) => {
                      this.selectArr.push(item1.id);
                    });
                  }
                }
              });
              this.$bus.$emit("wholeArr", this.selectArr);
            } else {

              this.aloneData.forEach((item, index) => {
                console.log("this.selectArr", item.list);

                if (item.list != null) {
                  item.list.forEach((item1, index1) => {
                    this.selectArr.push(item1.id);
                  });
                }
              });

              this.$bus.$emit("wholeArr", this.selectArr);
            }
            this.isShowDragList = true;
            this.selectArr = Array.from(new Set(this.selectArr));
            this.pointerData(); //二级树
            this.totalNum = JSON.parse(JSON.stringify(this.selectArr)).length;
            setTimeout(() => {
              // 获取高度
              this.getHeight();
            }, 50);
            console.log("this.selectArr", this.selectArr);
          }, 300);
        })
        .catch((err) => {});
    },

    // 测点
    pointerData() {
      this.selectArr = Array.from(new Set(this.selectArr));
      let targetType = this.selectArr.join(",");
      this.$axios
        // .get(`${this.baseURL}pandect/select/targertypegroup/list/two?bid=${this.projectId}`)
        // .get(`${this.baseURL}synthesis/target/list/${this.projectId}?code=${this.name}&typeIds=${typeids}`)
        .get(`${this.baseURL}stress/synthesize/tree/${this.projectId}?name=${this.name}&targetType=${targetType}`)
        .then((res) => {
          console.log("二级树", res);
          var data = res.data.data;
          // data.forEach((item, index) => {
          //   item.list.forEach((itm, idx) => {
          //     itm.name = itm.name ? itm.name : itm.code;
          //   });

          //   item.parentId = "0";
          // });

          this.data = data;
          console.log("sanshituarr", this.sanshituArr);

          this.$nextTick(() => {
            this.formateTreeSelectStatus(this.sanshituArr);
          });

          // this.currentNodeKey = this.sanshituArr[0] ? this.sanshituArr[0].targetId : "";
          // console.log("点开测点列表项", this.currentNodeKey, this.sanshituArr[0]);
          // if (this.$refs.tree) {
          //   this.$nextTick(() => {
          //     this.$refs.tree.setCurrentKey(this.currentNodeKey);
          //   });
          // }
        })
        .catch((err) => {});
    }
  }
};
</script>
<style scoped lang="scss">
.wholeIndex {
  width: 100%;
  height: 100%;
  padding: 20px 0;
  padding-top: 0px;
  padding-right: 0px;
  overflow-y: hidden;
  padding-bottom: 0px;
}

.main {
  width: 100%;
  height: 100%;
}

.left {
  width: 100%;
  height: 100%;
  margin-right: 24px;
  // border: 1px solid #ccc;
  overflow-y: auto;
}

.right {
  width: 56px;
  // height: 100%;
  // height: 108px;
  height: 160px;
  background: rgba($color: #4b6bfb, $alpha: 0.2);
  border-radius: 28px;
  padding-top: 17px;
  position: relative;

  .typeWin,
  .icon-list {
    position: absolute;
    z-index: 9;
    right: 80px;
    width: 320px;
    // height: 500px;
    // background: #042066;
    border-radius: 8px;
    opacity: 0.7;
    border: 1px solid;
    border-image: linear-gradient(300deg, rgba(155, 169, 203, 1), rgba(61, 107, 208, 1)) 1 1;
    overflow-y: auto;

    // padding: 10px;
    .bottom {
      border: none !important;
      padding-top: 10px;
      padding-bottom: 0px;
    }

    .itemBox {
      width: 64px;
      // margin-right: 28px;
      margin-bottom: 10px;
      letter-spacing: 0px !important;

      .itemBg {
        width: 34px;
        height: 34px;
        border-radius: 20px;
        color: #c3d2f7;
        background: rgba($color: #ccdaff, $alpha: 0.2);
      }

      .activeColor {
        color: #fff;
        background: #1f61ff !important;
      }
    }

    .itemBox1 {
      width: 28px;
      height: 28px;
      text-align: center;
      height: 28px;
      background: #3d4d6e;
      border-radius: 28px;
    }

    .itemBox:nth-child(4n) {
      margin-right: 0 !important;
    }
  }

  .pointerWin {
    position: absolute;
    z-index: 3;
    right: 80px;
    width: 304px;
    height: 714px;
    background: #042066;
    border-radius: 8px;
    border: 1px solid;
    border-image: linear-gradient(300deg, rgba(155, 169, 203, 1), rgba(61, 107, 208, 1)) 1 1;
    overflow-y: auto;
  }
}

.line1 {
  width: 100%;
  // height: 732px;   //632
  // margin-bottom: 14px;
}

.line2 {
  width: 100%;
  // height: 620px;
  // margin-bottom: 16px;
}

.ul1 {
  li {
    margin-bottom: 12px;
  }
}

.yuan {
  color: #ccdaff;
  width: 34px;
  height: 34px;
  text-align: center;
  line-height: 34px;
  border-radius: 28px;
  background: rgba($color: #ccdaff, $alpha: 0.2);
}

.fu {
  position: relative;
  // padding-left: 15px;
  // padding-right: 15px;
  width: calc(100% - 30px);
  height: 40px;
  border-radius: 20px;
  border: 1px solid #2667db;
  margin: 12px auto;
  // margin-top: 12px;
  // margin-bottom: 12px;

  .zi {
    position: absolute;
    right: 14px;
    top: 12px;
    padding-left: 12px;
    border-left: 1px solid #3a4a7b;
  }

  .el-input {
    width: 100%;
    height: 100%;

    // height: 40px;
    // border-radius: 20px;
    // border: 1px solid #2667db;
    // margin-top: 12px;
    // margin-bottom: 12px;
  }

  div.el-input .el-input__inner:hover,
  div.el-input .el-input__inner:focus {
    border-radius: 20px !important;
    border: 1px solid #2667db;
  }

  div.el-input .el-input__inner {
    border-radius: 20px !important;
  }
}

.typeName {
  letter-spacing: 0px !important;
}

.tab1 div {
  color: #fff !important;
  background: rgba($color: #ccdaff, $alpha: 0.4) !important;
}
.icon-active {
  color: #1f61ff;
}
::v-deep .el-tree-node__children .is-current {
  background-color: #002fa7;
}

.right-icon {
  flex-direction: column;
  height: 100%;
  align-items: center;
  width: 56px;
  // margin-right: 20px;
  // overflow: hidden;
  .icon-list {
    flex: 1;
    width: 56px;
    border-radius: 28px;
    background-color: rgba(75, 107, 251, 0.2);
    margin-top: 15px;
    font-family: SourceHanSansCN-Medium;
    font-weight: 500;
    font-size: 10px;
    color: #ffffff;
    letter-spacing: 0;
  }
}

.icon-list {
  // padding-top: 30px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  .transform180 {
    transform: rotate(180deg);
  }
  .icon-list-item {
    height: 100%;
    // border: 1px solid red;
    overflow: hidden;
  }
  .icon-list-slide {
    position: absolute;
    // width: 30px;
    height: 40px;
    // left: 50%;
    // margin-left: -15px;
    left: 0;
    right: 0;
    display: flex;
    // align-items: center;
    justify-content: center;
    z-index: 90;
  }
  .icon-list-shanghua {
    top: 38px;
    // padding-top: 30px;
    align-items: center;
    border-bottom: 1px solid #04153f;
    transform: scaleY(-1);
    background-image: linear-gradient(0deg, #0F245D 18%, #0134af00 74%);
    height: 90px;
    .icon-shanghua{
      position: relative;
      top: 26px;
    }

  }
  .icon-list-xiahua {
    height: 134px;
    bottom: 0px;
    // padding-bottom: 20px;
    align-items: center;
    background-image: linear-gradient(0deg, #0F245D 18%, #0134af00 74%);
    .icon-xiahua{
      position: relative;
      top: 42px;
    }
    }
  .icon-list-all {
    margin-top: 13px;
    width: 100%;
    height: 20px;
    text-align: center;
    line-height: 20px;
    margin-bottom: 12px;
  }
  .icon-list-line {
    position: relative;
    height: 3px;
    margin-top: 2px;
    margin-bottom: 12px;
    margin-left: 8px;
    margin-right: 8px;
    background-color: #2c4074;
    &:before {
      content: "";
      border: 1px dashed #3a4a7b;
      left: 0px;
      right: 0px;
      position: absolute;
      top: 5px;
    }
  }
  .dragNameListFelx{
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    &>div{
      flex: 1;
    }
  }
  .bottom {
    border: none !important;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .top {
    margin-top: 6px;
    text-align: center;
    letter-spacing: 0px;
  }
  .itemBox {
    width: 100%;
    // margin-right: 28px;
    margin-bottom: 10px;
    letter-spacing: 0px !important;

    text-align: center;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    .itemBg {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      color: #c3d2f7;

      background: rgba($color: #ccdaff, $alpha: 0.2);
    }

    .activeColor {
      color: #fff;
      background: #1f61ff !important;
    }
    .typeName {
      margin-top: 4px;
      font-family: SourceHanSansCN-Normal;
      font-weight: 400;
      font-size: 10px;
      color: #ccdaff;
      letter-spacing: 0;
    }
  }

  .itemBox1 {
    width: 28px;
    height: 28px;
    text-align: center;
    height: 28px;
    background: #3d4d6e;
    border-radius: 28px;
  }

  .itemBox:nth-child(4n) {
    margin-right: 0 !important;
  }
}
</style>
