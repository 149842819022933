<template>
  <div class="tableList winStyle">
    <div class="main">
      <!-- <div class="top box justify center">
        <div>数据表格</div>
        <div @click="getWin" class="pointer">
          <img src="../../../assets/images/img/set/shouqi_icon.png" alt="" />
        </div>
      </div> -->
      <div class="tableList_content bottom" v-if="win3 == 1">
        <div >
          <el-table class="" :row-class-name="tableRowClassName"
            :header-cell-style="{ background: '#04153F', color: '#fff' }" :data="tableData"
            @selection-change="handleSelectChange">
            <el-table-column align="center" prop="time" label="时间"> </el-table-column>

            <el-table-column align="center" prop="time" label="传感器编号">
              <div>{{ tableName.targetCode }}</div>
            </el-table-column>

            <el-table-column align="center" label="监测指标">
              <div>
                {{ tableName.param }}
              </div>
            </el-table-column>  

            <el-table-column align="center" label="处理方式">
              {{ timeActive | timeFilters }}
            </el-table-column>

            <el-table-column align="center" v-if="tableList && tableList.timeActive == 0" prop="data[0]" label="原始值">
            </el-table-column>

            <el-table-column align="center" v-if="tableList && tableList.timeActive != 0" prop="data[0]" label="最大值">
            </el-table-column>
            <el-table-column align="center" v-if="tableList && tableList.timeActive != 0" prop="data[1]" label="最小值">
            </el-table-column>
            <el-table-column align="center" v-if="tableList && tableList.timeActive != 0" prop="data[2]" label="平均值">
            </el-table-column>

            <div slot="empty">
              <p>
                <!-- <img src="@/assets/img/noWarn.png" style="width:120px"/> -->
                <span class="span">暂无信息</span>
              </p>
            </div>
          </el-table>
          <common-pagination v-show="tableData.length > 0" class="pageMain" :total="total" :currentPage="currentPage"
            @handleCurrentChange="handleCurrentChange" />
        </div>
      </div>
      <div class="bottom" v-if="win3 == 0"></div>
    </div>
  </div>
</template>

<script>
import CommonPagination from "../../../components/Pagination";
export default {
  name: "tableList",
  components: {
    CommonPagination
  },
  data() {
    return {
      win3: "1", //显示隐藏主体 0 1
      // 表格数据
      tableData: [],
      tableName: {},
      pageSize: 10,
      currentPage: 1,
      total: 1,
      timeActive: "",
      tableList: []
    };
  },
  //创建
  created() {
    this.$bus.$on("getTable", this.tableArr);
  },

  //安装
  mounted() { },

  //方法
  methods: {
    //  接收table的参数
    tableArr(e) {
      console.log("e-表格接收", e);

      this.tableList = e;
      this.currentPage = 1;
      this.getTableData();
    },

    getWin() {
      if (this.win3 == 1) {
        this.win3 = 0;
      } else {
        this.win3 = 1;
      }
    },

    // 表格
    handleSelectChange(val) {
      console.log(val);
    },

    // 表格
    getTableData() {
      console.log("this.tableList", this.tableList);
      // var target = this.value.id;
      // console.log('timeActive', this.timeActive);
      this.timeActive = this.tableList.timeActive;
      if (this.tableList.timeActive == 0) {
        // 原始值
        var path = `data/history/page/raw/${this.tableList.target}?interval=${this.tableList.timeActive}&sort=1&startTime=${this.tableList.startTime}&endTime=${this.tableList.endTime}&current=${this.currentPage}&size=10`;
      } else {
        // 均值
        var path = `data/history/page/raw/average/${this.tableList.target}?interval=${this.tableList.timeActive}&sort=1&startTime=${this.tableList.startTime}&endTime=${this.tableList.endTime}&current=${this.currentPage}&size=10`;
      }

      this.$axios
        .get(`${this.baseURL}${path}`)
        .then((res) => {
          // console.log('表格数据', res);
          this.tableData = res.data.data.datas;
          // console.log('table', this.tableData);
          this.tableName = {
            targetCode: res.data.data.targetCode,
            param: res.data.data.param[0],
            current: res.data.data.current
          };
          this.total = res.data.data.total;

          if(res.data.data.units && res.data.data.units.length){
            var unit = res.data.data.units[0];
            this.$bus.$emit("wholeUnitChange", unit);
          }
          if(res.data.data.param && res.data.data.param.length){
            var param = res.data.data.param[0];
            this.$bus.$emit("wholeParamChange", param);
          }
        })
        .catch((err) => { });
    },
    handleCurrentChange(val) {
      console.log(val);
      this.currentPage = val;
      this.getTableData();
    },
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    }
  }
};
</script>
<style scoped lang="scss">
.tableList {
  width: 100%;
  height: 100%;
}
.tableList_content{
  border-top: none;
  padding-top: 22px;
}

::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}
</style>
