<template>
  <!-- 单桥的信息框 -->
  <div class="targetWin">
    <div>
      <div class="color1 mT10 mB10" style="margin-left: 17px">{{ list.code }}:{{ list.site }}</div>
      <div class="imgBox">
        <img :src="src" alt="" />
      </div>
      <div class="box justify" style="margin-left: 40px; margin-right: 40px; margin-top: 17px">
        <table width="100%" border="1">
          <tr class="color1">
            <td width="100px">{{ list.typeName }}</td>
            <td>{{ list.installTime }}</td>
            <td>{{ list.status | statusData }}</td>
          </tr>
          <tr class="color2">
            <td>监测指标类型</td>
            <td>设备安装时间</td>
            <td>设备状态</td>
          </tr>
        </table>
        <!-- <div>
          <div class="color1">{{ list.typeName }}</div>
          <div class="color2">监测指标类型</div>
        </div>
        <div>
          <div class="color1">{{ list.installTime }}</div>
          <div class="color2">设备安装时间</div>
        </div>
        <div>
          <div class="color1">{{ list.status | statusData }}</div>
          <div class="color2">设备状态</div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import qs from "qs";
export default {
  name: "targetWin",
  data() {
    return {
      projectId: "",
      list: {},
      src: "", //require('../../assets/images/alone/chuttersnap-png.png')
      typeId: "",
      imgId: "" //图片id
    };
  },
  //创建
  created() {
    this.projectId = sessionStorage.getItem("projectId");
  },

  //安装
  mounted() {
    this.$bus.$on("targetHover", this.targetHover);
    //桥梁图片
  },

  //方法
  methods: {
    targetHover(e) {
      console.log("e传感器", e);
      this.src = "";
      var data = {
        targetId: e
      };
      this.$axios
        .post(`${this.baseURL}pandect/target/info/${e}`, data)
        .then((res) => {
          console.log("传感器信息", res.data.data);
          this.list = res.data.data;
          // this.typeId = this.list.typeId;
          this.imgData(this.list.id);
        })
        .catch((err) => {});
    },

    imgData(id) {
      // 根据传感器id查图片id
      this.$axios
        .get(`${this.baseURL}sys/sysImages/target/${id}`)
        .then((res) => {
          console.log("图片id", res.data.data);
          var data = res.data.data;
          if (data.length > 0) {
            this.showImg(data[0].id);
          }
        })
        .catch((err) => {});
    },

    showImg(id) {
      this.src = `${this.baseURL}sys/sysImages/img/${id}`;
      // this.$axios.get(`${this.baseURL}sys/sysImages/img/${id}`).then(res => {
      //         console.log('图片流', res.data);
      //         this.src = res.data;
      //     }).catch(err=>{
      // })
    }

    // 获取信息
    // getData(id){
    //   var data = {
    //     bid: id
    //   };
    //   this.$axios.post(`${this.baseURL}pandect/select/bridge`, qs.stringify(data)).then(res => {
    //         console.log('桥梁信息', res.data.data);
    //         var data = res.data.data;
    //         this.list = data;

    //     }).catch(err=>{
    //     })
    // },

    // // 封面图片
    //  getImg(id){
    //   var data = {
    //     bid: id
    //   };
    //   this.$axios.get(`${this.baseURL}sys/sysImages/cover/${this.projectId}`).then(res => {
    //         console.log('桥梁图片', res.data.data);
    //         var id = res.data.data[0].id;
    //         console.log('id', id);
    //         this.imgSrc1(id);
    //     }).catch(err=>{
    //     })
    // },

    //  imgSrc1(id){
    //    this.src = `${this.baseURL}sys/sysImages/img/${id}`
    // },

    // getU3d(){
    //     this.$router.push('/u3d');
    // }
  }
};
</script>
<style scoped lang="scss">
.targetWin {
  width: 100%;
  height: 296px;
  border: 1px solid #ccc;
  background: #fff;
}
.targetMain {
  width: 100%;
  height: 100%;
}
.imgBox {
  width: 100%;
  height: 196px;
  img {
    width: 100%;
    height: 100%;
  }
}
.color1 {
  font-size: 14px;
  color: #282d32;
}
.color2 {
  font-size: 12px;
  color: rgba($color: #282d32, $alpha: 0.5);
}
</style>
