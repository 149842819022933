<template>
  <!-- 单桥首页的数据查看 -->
  <div class="dataShow box box_column winStyle">
    <div class="close pointer" @click="close">
      <img src="../../../assets/images/all/guanbi_icon.png" alt="" />
    </div>
    <!-- <div class="top box justify center noFlex">
      <div>数据查看</div>
      <div class="pointer" @click="large()">
        <img src="../../../assets/images/img/set/shouqi_icon.png" alt="" />
      </div>
    </div> -->
    <div class="flex1 bottom box box_column noFlex" v-show="win2 == 1">
      <div class="box center justify mB10 mT20" >
        <div>
          <div class="box center">
            <div class="mR20" v-for="(item, index) in targetList" :key="index">{{ item.code || item.treeName }}</div>
          </div>
        </div>

        <!-- <div class="box end center pointer" @click="win2Title">
          <div class="mR10 fs14" style="color: #2772f0">{{ titleName }}</div>
          <div class="">
            <img src="../../../assets/images/img/set/Combined Shape.png" alt="" style="transform: rotate(180deg)" v-if="win22 == 1" />
            <img src="../../../assets/images/img/set/Combined Shape.png" alt="" v-if="win22 == 0" />
          </div>
        </div> -->
      </div>
      <div class="box justify mT10" v-if="win22 == 1">
        <div class="new_ui_select">
          <!-- <div class="box center">
                      <div class="mR20" v-for="(item,index) in targetList" :key="index">{{item.code}}</div>
                  </div> -->
          <!-- <el-select   v-model="value" value-key="id" placeholder="请选择" @change="changeTarget">
                     <el-option-group
                        v-for="group in options1"
                        :key="group.label"
                        :label="group.typeName">
                        <el-option
                            v-for="item in group.list"
                            :key="item.id"
                            :label="item.code"
                            :value="item">
                        </el-option>
                        </el-option-group>    
                </el-select> -->
        </div>
      </div>
      <div class="newUi box justify" style="margin-bottom: 20px" v-if="win22 == 1">
        <div class="box">
          <!-- 时间切换 -->
          <ul class="box center">
            <li style="margin-right: 8px" class="dayColorBg pointer" v-for="(item, index) in dayList" :key="index" :class="dayActive == item.id ? 'dayColor' : ''" @click="changeDay(item.id)">
              {{ item.name }}
            </li>
          </ul>
          <div class="timeUi" style="margin-right: 8px">
            <div class="icon1 pointer" @click="add">
              <img src="../../../assets/images/alone/left.png" alt="" />
            </div>
            <div class="icon2 pointer" @click="reduce">
              <img src="../../../assets/images/alone/right.png" alt="" />
            </div>
            <el-date-picker @change="selectDate" :clearable="false" v-model="time" type="datetimerange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy.MM.dd HH:mm" value-format="yyyy-MM-dd HH:mm:ss"> </el-date-picker>
          </div>
          <!-- 均值和原始值切换 -->
          <ul class="box center tooltipList">
            <li style="margin-right: 16px" class="dayColorBg pointer" v-for="(item, index) in timeList" :key="index" :class="timeActive == item.id ? 'dayColor' : ''" @click="changeTime(item.id)">
              {{ item.name }}
              <el-tooltip v-if='index == 0' class="tooltip" effect="dark" content="只展示最近5万条数据" placement="bottom">
                <div>
                </div>
              </el-tooltip>
            </li>
          </ul>
        </div>
        <div class="tab_new pointer" :class="tabActive == 2 ? 'tabColor' : ''" @click="tab('2')">数据导出</div>
        <!-- <el-select v-model="typeActive" placeholder="请选择" @change="changeType(item)">
                <el-option
                    v-for="item in typeList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"/>
            </el-select> -->
      </div>
      <!-- 图表 -->
      <div class="flex1 tu">
        <div class="box center" style="width: 100%; height: 100%">
          <div class="unitDiv" v-show="!showNoData">
            <div class="d1">单位：{{ unit }}</div>
          </div>
          <div class="flex1" style="width: 100%; height: 100%">
            <div class="box justify">
              <div class="fs16 box center" style="width: 200px;" :class="{ 'title-align': timeActive == 0 }">
                <img style="margin-right: 12px;" src="@/assets/images/fragment-title-icon.png" />
                {{ nameParams }}-过程线图
              </div>
              <div v-if="timeActive != 0">
                <div class="box" style="magin-bottom: 7px">
                  <div class="box center mR20">
                    <div class="fs14 mR10 charts-leage-title">最大值:</div>
                    <div class="fs16 charts-leage-value">{{ arrList.max }}</div>
                  </div>
                  <div class="box center mR20">
                    <div class="fs14 mR10 charts-leage-title">平均值:</div>
                    <div class="fs16 charts-leage-value">{{ arrList.avg }}</div>
                  </div>
                  <div class="box center mR20">
                    <div class="fs14 mR10 charts-leage-title">方差:</div>
                    <div class="fs16 charts-leage-value">{{ arrList.diff }}</div>
                  </div>
                  <div class="box center mR20">
                    <div class="fs14 mR10 charts-leage-title">最小值:</div>
                    <div class="fs16 charts-leage-value">{{ arrList.min }}</div>
                  </div>
                  <div class="box center mR20">
                    <div class="fs14 mR10 charts-leage-title">标准差:</div>
                    <div class="fs16 charts-leage-value">{{ arrList.number }}</div>
                  </div>
                  <div class="box center">
                    <div class="fs14 mR10 charts-leage-title">{{ isSpeed ? "均方根" : "极差" }}</div>
                    <div class="fs16 charts-leage-value">{{ arrList.range }}</div>
                  </div>
                </div>
              </div>
              <div style="font-size: 16px;width: 200px;"></div>
              <!-- <div class="mL60">{{name}}-过程线图</div> -->
            </div>
            <div v-if="length > 0" style="height: 5px"></div>
            <div v-if="length > 1" style="height: 40px"></div>
            <div v-show="!showNoData" >
              <div id="echarts" class="flex1 echarts" style=""></div>
            </div>
            <div class="noDataMain" v-show="showNoData">
              <img src="../../../assets/images/noData.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
        </div>
        <div class="tuBg" :class="{tuBg1 :length > 1}"></div>
      </div>
    </div>
    <div class="bottom box box_column noFlex" v-show="win2 == 0"></div>
  </div>
</template>

<script>
import moment from "moment";
import qs from "qs";

export default {
  name: "dataView",
  props: ["closeShow"],
  data() {
    return {
      showNoData:false,
      // chartsDom: null,
      projectId: "",
      titleName: "展开查看全部功能", //  展开查看全部功能
      win2: "1", //整体隐藏
      win22: "1", //部分隐藏
      //  二级树形
      options1: [],
      value: "", //测点
      unit: "", //单位
      targetId: "", //选中id
      name: "", //测点名称
      nameParams:"",

      tabActive: "", //tab切换

      dayActive: "3", //日期选择  默认选择近10日
      dayList: [
        { id: "1", name: "当天" },
        { id: "2", name: "本周" },
        { id: "3", name: "近10天" },
        { id: "4", name: "本月" }
      ],
      timeList: [
        { id: "0", name: "原始值" },
        { id: "1", name: "1分钟均值" },
        { id: "2", name: "10分钟均值" },
        { id: "3", name: "1小时均值" },
        { id: "4", name: "日均值" }
      ],
      timeActive: 2, //默认选择10分钟均值
      typeActive: "1",
      typeList: [
        { id: "1", name: "趋势分析" },
        { id: "2", name: "趋势分析" },
        { id: "3", name: "趋势分析" }
      ],
      time: "",
      time1: "", //存放时间戳
      time2: "",
      size: "50000", //图表最多显示多少条

      // 默认选中值
      selected: "A",
      // 数据默认字段
      defaultProps: {
        parent: "parentId", // 父级唯一标识
        value: "id", // 唯一标识
        label: "label", // 标签显示
        children: "children" // 子级
      },
      arrList: {
        number: ""
      }, //计算后的6个值

      arrData: [], //曲线总条数
      time: [],

      length: "0",

      color: ["#2772F0", "#80C0FF", "#F7B500", "#FFBA4C", "#9091AE", "#3180FF", "#b66dbb", "#c9d0f7", "#fa5757", "#03f5f7", "#ffae00", "#c9d0f7", "#ff5707"],

      targetList: [], //三视图传过来的测点数组。
      isSpeed: false // 是否是加速度
    };
  },
  //创建
  created() {
    sessionStorage.setItem("CEDIANLIST", "");
    this.projectId = sessionStorage.getItem("projectId");
    this.$bus.$off("targetArr");
    // this.$bus.$off()
    this.$bus.$on("targetArr", this.targetArr); //从三视图传过来的数组id
    this.$bus.$on("wholeUnitChange", (data)=>{
      this.unit = data
    }); 
    this.$bus.$on("wholeParamChange", (data)=>{
      this.nameParams = data
    }); 
    
    // this.$bus.$off("changeNodeClick");
    // this.$bus.$on("changeNodeClick", this.targetArr); //开关是否可以追加
  },
  watch: {},
  destroyed() {
    sessionStorage.removeItem("CEDIANLIST");
  },

  //安装
  mounted() {
    var day = new Date();
    var day1 = day.getTime(); //获得当前时间戳
    var day2 = day1 - 10 * 24 * 60 * 60 * 1000;

    this.time2 = day.getTime(); //当前时间戳
    this.time1 = day1 - 10 * 24 * 60 * 60 * 1000; //中国标准时间

    var start = moment(day2).format("YYYY-MM-DD HH:mm:ss");
    var end = moment(day).format("YYYY-MM-DD HH:mm:ss");
    console.log(start, end);
    this.time = [];
    this.time.push(start);
    this.time.push(end);

    // this.changeTime(3);
    // this.timeActive = 3;
    this.treeData();
  },
  // computed: {
  //   targetListChange() {
  //     console.log("测点列表变化", this.targetList);
  //     return this.targetList;
  //   }
  // },
  //方法
  methods: {
    selectDate() {
      console.log("selectDate", this.time);
      this.dayActive = 0;
      this.selectData(); //切换历史数据与均值
    },
    //   接收三视图传过来的值
    targetArr(e) {
      this.targetList = e; //保存传过来id
      this.length = e.length;

      // if (this.win == 0) return;

      let cedianList = sessionStorage.getItem("CEDIANLIST");
      // if (!cedianList) {
      //   console.log("aaaaaaaaaaaa");
      //   sessionStorage.setItem("CEDIANLIST", JSON.stringify(e));
      // } else {
      //   if (JSON.stringify(e) == cedianList) {
      //     console.log("bbbbbbbbbbb");
      //     return;
      //   }
      // }
      // sessionStorage.setItem("CEDIANLIST", JSON.stringify(e));

      // 多条显示

      if (e.length > 1) {
        //   ----- length>1代表多个测点
        this.changeLine(e);
        // this.unit = e[0].unit;
        this.setSpeed(e[0].type);
      } else {
        //  length = 0 图表就为空
        if (e.length == 0) {
          this.$chart1.line("echarts", [], [], "", "", true);
          return;
        }
        this.targetId = e[0].targetId ? e[0].targetId : e[0].treeTargetId;
        this.unit = e[0].unit;
        this.setSpeed(e[0].type);
        var data = {
          target: this.targetId,
          timeActive: this.timeActive,
          startTime: this.time[0],
          endTime: this.time[1]
        };
        if (this.timeActive == 0) {
          this.warningData = [];
          this.$bus.$emit("getTable", data); //单条的时候查询列表
          // this.data1(); //进入原始值
          this.getWarningData(2);
        } else {
          this.$bus.$emit("getTable", data); //单条的时候查询列表
          // this.data(); //进入均值
          this.getWarningData(1);
        }
      }
    },

    // 判断是否是加速度类型
    setSpeed(type) {
      if (type === 1008) {
        this.isSpeed = true;
      } else {
        this.isSpeed = false;
      }
    },

    dong() {
      if (this.closeShow == 1) {
        this.closeShow = 0;
        setTimeout(() => {
          this.closeShow = 1;
        }, 10);
      }
      // let myChart = this.$echarts.init(document.getElementById('echarts'));
      // myChart.setOption(option)
      // window.addEventListener("resize", () => { myChart.resize();});

      // let erd = elementResizeDetectorMaker();
      // let that = this;
      // erd.listenTo(document.getElementById("echarts"), (element) => {
      //     let width = element.offsetWidth;
      //     let height = element.offsetHeight;
      //     console.log(width, height)
      //     that.$nextTick(() => {
      //         //使echarts尺寸重置
      //     that.$echarts.init(document.getElementById("echarts")).resize();
      //     });
      // });
    },

    // 关闭窗口
    close() {
      this.closeShow = 0;
      this.$emit("getAdd", "0"); // 给父组件的
      var Dom = document.getElementById("largeWin");
      Dom.style.width = "1326px";
      Dom.style.marginLeft = "-663px";
      Dom.style.left = "50%";
      this.timeActive = "2";
      this.tabActive = ""; //
    },
    // 显示隐藏主体
    large() {
      console.log("图表展示隐藏", this.win2);
      if (this.win2 == "0") {
        console.log("图表展示隐藏1111111111111111");
        this.win2 = "1";

        // this.data();
        this.$nextTick(() => {
          console.log("图表点开", this.targetList);
          sessionStorage.setItem("CEDIANLIST", "");
          this.targetArr(this.targetList);
        });

        // setTimeout(() => {

        //   this.targetArr(this.targetList)

        // }, 0);
      } else {
        console.log("图表展示隐藏222222222222");
        this.win2 = "0";
        // if (this.chartsDom) {
        //   this.chartsDom.dispose()
        //   this.chartsDom = null
        // }
        document.getElementById("echarts").removeAttribute("_echarts_instance_");
        // this.targetArr(this.targetList)
        sessionStorage.setItem("CEDIANLIST", "");
      }
      //  var Dom  = document.getElementById('largeWin');
      //  if( Dom.style.width == "100%"){
      //      this.$bus.$emit('largeWin','2');      //缩小
      //  }else{
      //      this.$bus.$emit('largeWin','1');    //缩小
      //  }
    },
    win2Title() {
      if (this.win22 == 1) {
        this.titleName = "展开查看全部功能";
        this.win22 = 0;
      } else {
        this.titleName = "收起查看全部功能";
        this.win22 = 1;
      }
    },

    // 时间左
    add() {
      this.time1 = this.time1 - 24 * 60 * 60 * 1000;
      var start = moment(this.time1).format("YYYY-MM-DD HH:mm:ss");
      this.$forceUpdate();
      this.time[0] = start;
      this.time = [this.time[0], this.time[1]];
      console.log("time", this.time);
      this.dayActive = 0;
      this.selectData(); //切换历史数据与均值
    },
    // 时间右
    reduce() {
      this.time2 = this.time2 + 24 * 60 * 60 * 1000;
      var end = moment(this.time2).format("YYYY-MM-DD HH:mm:ss");
      this.$forceUpdate();
      this.time[1] = end;
      this.time = [this.time[0], this.time[1]];
      this.dayActive = 0;
      this.selectData(); //切换历史数据与均值
    },

    // 时间切换
    changeDay(id) {
      console.log("快捷选天", id);
      var day = new Date();

      var time2 = day.getTime(); //当前时间戳
      this.time2 = time2; //保存当前时间时间戳
      time2 = moment(time2).format("YYYY-MM-DD HH:mm:ss");
      console.log("time2", time2);

      // 获取当天
      if (id == 1) {
        var time1 = new Date(new Date().toLocaleDateString()).getTime(); //当天时间戳
        this.time1 = time1; //保存开始处时间错
        time1 = moment(time1).format("YYYY-MM-DD HH:mm:ss");
        console.log("time1", time1);
        this.time = [time1, time2];
      }
      // 本周
      if (id == 2) {
        var nowTemp = new Date(); //当前时间
        var oneDayLong = 246060 * 1000; //一天的毫秒数
        var c_time = nowTemp.getTime(); //当前时间的毫秒时间
        var c_day = nowTemp.getDay() || 7; //当前时间的星期几
        var m_time = c_time - (c_day - 1) * oneDayLong; //当前周一的毫秒时间
        var monday = new Date(m_time); //设置周一时间对象
        var m_year = monday.getFullYear();
        var m_month = monday.getMonth() + 1;
        var m_date = monday.getDate();
        var time = m_year + "-" + m_month + "-" + m_date + " " + "00:00:00"; //周一的年月日
        console.log("time", time);
        this.time = [time, time2];
        this.time1 = monday; //获取周一0点时间戳
      }
      // 近十天
      if (id == 3) {
        var day = new Date();
        var day1 = day.getTime(); //获得当前时间戳
        var day2 = day1 - 10 * 24 * 60 * 60 * 1000;
        this.time1 = day2;
        // this.time1 = day.getTime();                   //当前时间戳
        // this.time2 = day1 - 10*24 * 60 * 60 * 1000;  //中国标准时间
        var start = moment(day2).format("YYYY-MM-DD HH:mm:ss");
        var end = moment(day).format("YYYY-MM-DD HH:mm:ss");
        this.time = [start, end];
      }
      // 本月
      if (id == 4) {
        var data = new Date();
        data.setDate(1);
        data.setHours(0);
        data.setSeconds(0);
        data.setMinutes(0);
        var time = this.formatDateTime(data.getTime());
        this.time = [time, time2];
      }

      this.dayActive = id;

      // this.data();    //获取均值数据
      this.selectData(); //判断是原始值，还是均值
    },
    formatDateTime(inputTime) {
      var date = new Date(inputTime);
      this.time1 = date;
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var minute = date.getMinutes();
      var second = date.getSeconds();
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
    },
    // 数据列表/数据导出
    tab(val) {
      this.tabActive = val;
      if (val == 1) {
        this.getTableData();
      }
      if (val == 2) {
        this.dataExport(); //数据导出
      }
    },

    // 下拉选择设备
    changeTarget(item) {
      console.log("item", item);

      this.value = item;
      this.unit = item.unit;
      // this.name = item.name;

      this.selectData(); //切换历史数据与均值
    },
    // 选择类型
    changeType() {},

    // 表格
    handleSelectChange(val) {
      console.log(val);
    },
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },

    // -------------------------数据请求-----------------------------------
    // 测点树形
    treeData() {
      this.$axios
        .get(`${this.baseURL}pandect/select/targertypegroup/list/two?bid=${this.projectId}`)
        .then((res) => {
          console.log("树形数据", res);
          this.options1 = res.data.data;
          this.value = this.options1[0].list[0];
          // this.unit = this.options1[0].list[0].unit;
          // this.name = this.options1[0].list[0].name;
          this.targetId = this.options1[0].list[0].id;
          // this.selectData();  //切换历史数据与均值

          //  var data = {
          //     'target': this.targetId,
          //     'timeActive': this.timeActive,
          //     'startTime': this.time[0],
          //     'endTime': this.time[1]
          // };
          // this.$bus.$emit('getTable', data)
        })
        .catch((err) => {});
    },
    // 数据导出
    dataExport() {
      var targetId = this.targetId; // 传感器id
      // console.log('time', this.time,this.time[0], this.time[1]);
      // return;
      var data = {
        targetId: targetId,
        startTime: this.time[0],
        endTime: this.time[1],
        type: this.timeActive,
        sort: 1,
        size: this.size
      };

      if (this.timeActive == 0) {
        this.$axios
          .get(`${this.baseURL}pandect/original/excel/${targetId}?sort=1&startTime=${this.time[0]}&endTime=${this.time[1]}&size=${this.size}`, { responseType: "blob" })
          .then((res) => {
            console.log("数据导出历史数据", res);
            let a = document.createElement("a");
            let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
            let objectUrl = URL.createObjectURL(blob);
            a.setAttribute("href", objectUrl);
            a.setAttribute("download", "数据导出.xlsx");
            a.click();
          })
          .catch((err) => {});
      } else {
        this.$axios
          .post(`${this.baseURL}pandect/select/target/data/excel`, qs.stringify(data), { responseType: "blob" })
          .then((res) => {
            console.log("数据导出均值", res);
            let a = document.createElement("a");
            let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
            let objectUrl = URL.createObjectURL(blob);
            a.setAttribute("href", objectUrl);
            a.setAttribute("download", "数据导出.xlsx");
            a.click();
          })
          .catch((err) => {});
      }
    },
    pushArr(name, line, data, color) {
      console.log("pushArr");
      return {
        silent: true,
        lineStyle: {
          type: line,
          color: color,
          width: 1
        },
        label: {
          formatter: name
        },
        yAxis: data
      };
    },
    getWarningData(flag = 1) {
      this.warningData = [];
      this.$axios
        .post(`${this.baseURL}pandect/quota/line/${this.targetId}`)
        .then((res) => {
          console.log("预警标线", res, "flag", flag);

          let obj = res.data.data;
          let colorarr = ["rgba(218, 78, 78, 1)", "rgba(247, 142, 46, 0.4)", "rgba(59, 120, 195, 1)", "rgba(131, 187, 255, 0.6)"];
          let typeArr = ["solid", "solid", "solid", "solid"];
          let objarr = [];
          // "targetId": 219,
          // "duration": 1,
          // "alower": 90,
          // "blower": 80,
          // "clower": 9,
          // "dupper": 1,
          // "eupper": 2,
          // "fupper": 5,
          // "levelFirst": "1",
          // "levelSecond": "1",
          // "levelThird": ""
          if (obj["levelFirst"] == "1") {
            if (obj["alower"]) {
              objarr.push(this.pushArr("重度预警", "solid", obj["alower"], colorarr[0]));
            }
            if (obj["dupper"]) {
              objarr.push(this.pushArr("重度预警", "solid", obj["dupper"], colorarr[0]));
            }
            console.log("objarr", objarr);
          }
          if (obj["levelSecond"] == "1") {
            if (obj["blower"]) {
              objarr.push(this.pushArr("中度预警", "solid", obj["blower"], colorarr[1]));
            }
            if (obj["eupper"]) {
              objarr.push(this.pushArr("中度预警", "solid", obj["eupper"], colorarr[1]));
            }
            console.log("objarr", objarr);
          }
          if (obj["levelThird"] == "1") {
            if (obj["clower"]) {
              objarr.push(this.pushArr("轻度预警", "solid", obj["clower"], colorarr[2]));
            }
            if (obj["fupper"]) {
              objarr.push(this.pushArr("轻度预警", "solid", obj["fupper"], colorarr[2]));
            }
            console.log("objarr", objarr);
          }

          this.warningData = objarr;
          if (flag == 1) {
            console.log("this.data()");
            this.data();
          } else {
            console.log("this.data11111()");
            this.data1();
          }
          // this.selectData();
        })
        .catch((err) => {});
    },
    //  均值选择 timeId == 选择的id
    changeTime(timeId) {
      // if (timeId == 0) {
      //   var time2 = new Date().getTime(); //当前时间戳
      //   this.time2 = time2; //保存当前时间时间戳
      //   time2 = moment(time2).format("YYYY-MM-DD HH:mm:ss");
      //   var time1 = new Date(new Date().getTime() - 1 * 60 * 60 * 1000);
      //   time1 = moment(time1).format("YYYY-MM-DD HH:mm:ss");
      //   this.time = [time1, time2];
      //   console.log(this.time)
      // }
      this.timeActive = timeId; //均值的时间id
      this.selectData();
    },

    // 切换历史数据与均值
    selectData() {
      // timeActive =0 是历史数据     length>1 代表多条
      setTimeout(() => {
        var length = this.targetList.length;
        this.length = length;
        var data = {
          target: this.targetId,
          timeActive: this.timeActive,
          startTime: this.time[0],
          endTime: this.time[1]
        };
        if (this.timeActive == 0) {
          if (length > 1) {
            this.changeLine1(this.targetList); //多条曲线的   --- 历史
          } else {
            this.$bus.$emit("getTable", data); //单条的时候查询列表
            this.data1(); //进入原始值
          }
        } else {
          if (length > 1) {
            this.changeLine(this.targetList); //多条曲线 ----- 均线
          } else {
            this.$bus.$emit("getTable", data); //单条的时候查询列表
            this.data(); //进入均值
          }
        }
      }, 300);
    },

    //  均值数据请求
    data() {
      console.log("time", this.time);
      // var target = this.value;   //设备id
      // var target = this.value.id;   //设备id
      var target = this.targetId; //设备id
      var timeId = this.timeActive;
      // var sort = 0;   //排序(0:正序，1:倒序)
      // this.loadingPage = this.$loading({
      //         lock: true,
      //         text: '正在读取...',
      //         spinner: 'el-icon-loading',
      //         background: 'rgba(0, 0, 0, 0.2)',
      //         target: document.querySelector(".tuBg")   //loading需要覆盖的DOM节点，默认为body
      // });
      var path = `data/history/raw/average/${target}?interval=${timeId}&sort=0&startTime=${this.time[0]}&endTime=${this.time[1]}&size=${this.size}`;
      this.$axios
        .get(`${this.baseURL}${path}`)
        .then((res) => {
          console.log("历史均值", res);
          // data[最大值,最小值,平均值,标准差,方差,极差]
          // 最大值， 最小值 ， 平均值， 标准值， 方差 ，极差。

          var arr = res.data.data;
          this.arrList.number = arr.number;
          this.arrList.min = arr.min;
          this.arrList.avg = arr.avg;
          this.arrList.max = arr.max;
          this.arrList.range = arr.range;
          this.arrList.diff = arr.diff;


          var data = res.data.data.data;
          var time = [];
          var max = [];
          var min = [];
          var average = [];
          var standard = [];
          var variance = [];
          var range = [];

          data.forEach((item, index) => {
            time.push(item.time);
            max.push(item.data[0]);
            min.push(item.data[1]);
            average.push(item.data[2]);
            standard.push(item.data[3]);
            variance.push(item.data[4]);
            range.push(item.data[5]);
          });

          var name = ["最大值", "均值", "最小值"];
          //  console.log('unit 649 649 649 649', this.unit);

          // console.log("传递图表数据", time, "time", max, min, average, standard, variance, range, name, " this.warningData", this.warningData);

          document.getElementById("echarts").removeAttribute("_echarts_instance_");
          // console.log('document.getElementById("echarts")', document.getElementById("echarts"), this.$chart1);
          // console.log('8888888888888-----------------8888', this.warningData)
          console.log("---------------66-----------------------");
          if (this.warningData[0] && this.warningData[0].yAxis == null) {
            var dataInfor = [];
          } else {
            var dataInfor = this.warningData;
          }
          this.$nextTick(() => {
            this.$chart1.moreLine("echarts", time, max, min, average, standard, variance, range, name, "", false, dataInfor);
          });

          // alert('66666---')
          // this.loadingPage.close();
          // console.log(' this.chartsDom', this.chartsDom)
        })
        .catch((err) => {});
    },

    //  历史数据请求
    data1() {
      // console.log('time', this.time);
      // var target = this.value;   //设备id
      // var target = this.value.id;   //设备id
      var target = this.targetId; //设备id
      var timeId = this.timeActive;
      var sort = 0; //排序(0:正序，1:倒序)
      // this.loadingPage = this.$loading({
      //         lock: true,
      //         text: '正在读取...',
      //         spinner: 'el-icon-loading',
      //         background: 'rgba(0, 0, 0, 0.2)',
      //         target: document.querySelector(".tu")   //loading需要覆盖的DOM节点，默认为body tu tuBg
      // });

      var path = `data/history/raw/${target}?sort=1&startTime=${this.time[0]}&endTime=${this.time[1]}&size=${this.size}&reverse=1`; // 1 对数组反转
      this.$axios
        .get(`${this.baseURL}${path}`)
        .then((res) => {
          console.log("历史数据", res, this.warningData);
          // data[最大值,最小值,平均值,标准差,方差,极差]
          // 最大值， 最小值 ， 平均值， 标准值， 方差 ，极差。

          this.arrList.number = "";
          this.arrList.min = "";
          this.arrList.avg = "";
          this.arrList.max = "";
          this.arrList.range = "";
          this.arrList.diff = "";

          console.log("arrList", this.arrList);

          var data = res.data.data;
          var timeX = [];
          var dataY = [];

          console.log("data", data);

          data.forEach((item, index) => {
            timeX.push(item.time);
            dataY.push(item.data[0]);
          });

          var name = ["原始值"];
          document.getElementById("echarts").removeAttribute("_echarts_instance_");
          this.$chart1.line("echarts", timeX, dataY, name, "", false, this.warningData);
          // this.loadingPage.close();
        })
        .catch((err) => {});
    },

    // changeLine   多条曲线 均线
    // changeLine1   多条曲线 历史

    // 多条曲线选择   默认选择的是均线
    changeLine(arr) {
      // console.log('arr是bus传过来的数组');
      this.arrData = []; //清空所有图表
      // var arr = ['187', '216'];
      var list = [];
      arr.forEach((item, index) => {
        // list.push(item.targetId)
        this.changeTimeList(this.timeActive, (item.targetId || item.treeTargetId), index,(item.code || item.treeName));
      });
      // list是id数组[111,222]
      // list.forEach((item, index)=>{
      //     this.changeTimeList(this.timeActive,  item, index);    //1均值选项， item=id号
      //     // this.$chart1.moreLine1('echarts',this.time,this.arrData,'');
      // })
    },

    //  均值选择 多条
    changeTimeList(selectId, id, numIndex, targetName) {
      this.timeActive = selectId;
      var target = id; //设备id
      // var sort = 0;   //排序(0:正序，1:倒序)
      // this.loadingPage = this.$loading({
      //         lock: true,
      //         text: '正在上传...',
      //         spinner: 'el-icon-loading',
      //         background: 'rgba(0, 0, 0, 0.2)',
      //         target: document.querySelector(".tuBg")   //loading需要覆盖的DOM节点，默认为body
      // });
      var path = `data/history/raw/average/${target}?interval=${selectId}&sort=1&startTime=${this.time[0]}&endTime=${this.time[1]}&size=${this.size}`;
      this.$axios
        .get(`${this.baseURL}${path}`)
        .then((res) => {

          console.log("历史均值", id, res);
          // data[最大值,最小值,平均值,标准差,方差,极差]
          // 最大值， 最小值 ， 平均值， 标准值， 方差 ，极差。

          // var arr = res.data.data;
          // this.arrList.number = arr.number;
          // this.arrList.min = arr.min;
          // this.arrList.avg = arr.avg;
          // this.arrList.max = arr.max;
          // this.arrList.range = arr.range;
          // this.arrList.diff = arr.diff;
          var data = res.data.data.data;
          console.log("mkmkmkmkmm",data);
          

          var time = [];
          var max = [];
          var min = [];
          var average = [];
          var standard = [];
          var variance = [];
          var range = [];

          data.forEach((item, index) => {
            time.push(item.time);
            // max.push(item.data[0]);
            // min.push(item.data[1]);
            average.push(item.data[2]);
            // standard.push(item.data[3]);
            // variance.push(item.data[4]);
            // range.push(item.data[5]);
          });

          // if(id ==187){
          //     this.time = time;
          // }

          var arrData = [];
          arrData.push(max);
          arrData.push(min);
          arrData.push(average.reverse());
          // arrData.push(standard)
          // arrData.push(variance)
          // arrData.push(range)
          var name = ["最大值", "最小值", "均值"];
          var color = ["#2772F0", "#80C0FF", "#F7B500"];

          // arrData.forEach((item, index) => {
          //   var templete = {
          //     data: item,
          //     name: targetName + name[index],
          //     type: "line",
          //     showSymbol: false, //是否显示原点
          //     smooth: true, //开启光滑
          //     itemStyle: {
          //       normal: {
          //         // color: color[numIndex*index],     // 小圆点颜色
          //         lineStyle: {
          //           width: 3,
          //           type: "solid" //'dotted'虚线 'solid'实线
          //           // color: color[numIndex*index]     //线条颜色
          //         }
          //       }
          //     }
          //   };
          //   this.arrData.push(templete);
          // });

          let templates = {
            name: targetName + "均值",
            type: "line",
            data: average,
            symbol: "none"
          };

          this.arrData.push(templates);

          console.log("arrData", this.arrData);
          var hadDataTag = false
          for (let i = 0; i < this.arrData.length; i++) {
            if(this.arrData[i].data && this.arrData[i].data.length){
              hadDataTag = true
            }
          }
          // if(hadDataTag){
          //   this.showNoData = false
          // }else{
          //   this.showNoData = true
          // }
          document.getElementById("echarts").removeAttribute("_echarts_instance_");
          // return;
          setTimeout(() => {
            this.$chart1.moreLine1("echarts", time.reverse(), this.arrData.reverse(), "");
          }, 500);
          // this.$chart1.moreLine('echarts',time, max, min, average, standard, variance, range,'');
          // this.loadingPage.close();
        })
        .catch((err) => {});
    },

    // 多条曲线的历史数据查询
    changeLine1(arr) {
      this.arrData = []; //清空所有图表
      // var arr = ['187', '216'];
      var list = [];
      arr.forEach((item, index) => {
        list.push(item.targetId || item.treeTargetId);
      });

      list.forEach((item, index) => {
        this.changeTimeList1(this.timeActive, item, index); //1均值选项， item=id号
        // this.$chart1.moreLine1('echarts',this.time,this.arrData,'');
      });
    },

    //  原始数据  多条
    changeTimeList1(selectId, id, numIndex) {
      this.timeActive = selectId;
      var target = id; //设备id
      // var sort = 0;   //排序(0:正序，1:倒序)
      // this.loadingPage = this.$loading({
      //         lock: true,
      //         text: '正在上传...',
      //         spinner: 'el-icon-loading',
      //         background: 'rgba(0, 0, 0, 0.2)',
      //         target: document.querySelector(".tuBg")   //loading需要覆盖的DOM节点，默认为body
      // });
      var path = `data/history/raw/average/${target}?interval=${selectId}&sort=1&startTime=${this.time[0]}&endTime=${this.time[1]}&size=${this.size}`;
      var path = `data/history/raw/${target}?sort=1&startTime=${this.time[0]}&endTime=${this.time[1]}&size=${this.size}`;
      this.$axios
        .get(`${this.baseURL}${path}`)
        .then((res) => {
          console.log("mdkamkda","changeTimeList1");
          console.log("原始数据", id, res.data.data);
          // data[最大值,最小值,平均值,标准差,方差,极差]
          // 最大值， 最小值 ， 平均值， 标准值， 方差 ，极差。

          var arr = res.data.data;

          var time = [];
          var dataY = [];

          arr.forEach((item, index) => {
            time.push(item.time);
            dataY.push(item.data[0]);
          });

          // if(id ==187){
          //     this.time = time;
          // }

          var arrData = [];
          // arrData.push(time)
          arrData.push(dataY);

          var name = ["历史数据"];
          var color = ["#2772F0", "#80C0FF", "#F7B500"];

          arrData.forEach((item, index) => {
            var templete = {
              data: item,
              name: id + name[index],
              type: "line",
              showSymbol: false, //是否显示原点
              smooth: true, //开启光滑
              itemStyle: {
                normal: {
                  // color: color[numIndex*index],     // 小圆点颜色
                  lineStyle: {
                    width: 3,
                    type: "solid" //'dotted'虚线 'solid'实线
                    // color: color[numIndex*index]     //线条颜色
                  }
                }
              }
            };
            this.arrData.push(templete);
          });
          console.log("arrData===多条曲线", this.arrData);
          document.getElementById("echarts").removeAttribute("_echarts_instance_");
          // return;
          setTimeout(() => {
            this.$chart1.moreLine1("echarts", time.reverse(), this.arrData, "");
          }, 500);
          // this.$chart1.moreLine('echarts',time, max, min, average, standard, variance, range,'');
          // this.loadingPage.close();
        })
        .catch((err) => {});
    },

    targetListData() {
      var arr = ["187", "216"];
      arr.forEach((item, index) => {});
    },

    // 表格
    getTableData() {
      var target = this.value.id;
      console.log("timeActive", this.timeActive);
      var path = `data/history/page/raw/average/${target}?interval=${this.timeActive}&sort=0&startTime=${this.time[0]}&endTime=${this.time[1]}&current=${this.currentPage}&size=10`;
      this.$axios
        .get(`${this.baseURL}${path}`)
        .then((res) => {
          this.tableData = res.data.data.datas;
          this.tableName = {
            targetCode: res.data.data.targetCode,
            param: res.data.data.param[0],
            current: res.data.data.current
          };
          this.total = res.data.data.total;
    
        })
        .catch((err) => {});
    },
    handleCurrentChange(val) {
      console.log(val);
      this.currentPage = val;
      this.getTableData();
    }
  }
};
</script>
<style scoped lang="scss">
.dataShow {
  width: 100%;
  // height: 100%;
}

// 默认tab切换
.tab_new {
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  width: 96px;
  height: 32px;
  border-radius: 16px;
  border: 1px solid #2667db;
}

// 选中tab
.tabColor {
  font-size: 14px;
  width: 96px;
  height: 32px;
  background: #2667db;
  border-radius: 16px;
  border: 1px solid #233361;
}
.tooltipList{
  li:nth-child(1){
    margin-right: 37px !important;
  }
}
.dayColorBg {
  // width: 80px;
  padding-left: 22px;
  padding-right: 22px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 12px;
  color: #7387c6;
  background: #233361;
  border-radius: 15px;
  letter-spacing: 0px;
  position: relative;
  .tooltip{
    position: absolute;
    right: -21px;
    width: 14px;
    height: 14px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url('../../../assets/images/tipoff.png');
    background-size: 100% 100%;
    &:hover{
      background-image: url('../../../assets/images/tipon.png');
    }
  }
}

.dayColor {
  color: #cedaff !important;
  background: #0050b4 !important;
}

::v-deep .newUi {
  .el-select {
    width: 118px;
    height: 32px !important;

    .el-input {
      height: 32px !important;

      .el-input__inner {
        border: 1px solid #233361 !important;
        border-radius: 16px !important;
      }

      .el-input__icon {
        line-height: 17px;
      }
    }
  }

  .timeUi {
    position: relative;

    .icon1 {
      position: absolute;
      top: 12px;
      left: 6px;
      z-index: 99;
    }

    .icon2 {
      position: absolute;
      top: 12px;
      right: 6px;
      z-index: 99;
    }

    .el-range-editor.el-input__inner {
      width: 264px;
      height: 30px;
      line-height: 30px;
      border: 1px solid #233361 !important;
      background: #04153f;
      border-radius: 16px !important;
    }

    .el-range-input {
      width: 112px;
      letter-spacing: 0px;
      font-size: 12px !important;
      background: transparent !important;
    }

    // 至
    .el-date-editor .el-range-input,
    .el-date-editor .el-range-separator {
      color: #ffffff;
      height: 30px !important;
    }

    // 头部图标
    .el-date-editor .el-range__icon {
      display: none !important;
    }
  }
}

::v-deep .new_ui_select {
  .el-select {
    width: 200px;
    height: 32px !important;

    .el-input {
      height: 32px !important;

      .el-input__inner {
        border: 1px solid #233361 !important;
        border-radius: 16px !important;
      }

      .el-input__icon {
        line-height: 17px;
      }
    }
  }
}

// 图表样式
.tu {
  width: 100%;
  // height: 100%;
  position: relative;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
}

.tuBg {
  position: absolute;
  background: rgba($color: #fff, $alpha: 0.05);
  // border-radius: 24px;
  width: 100%;
  height: 360px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
}
.tuBg1 {
  height: 390px;
}

.legend1 {
  width: 24px;
  height: 3px;
  background: #2772f0;
  border-radius: 2px;
}

.legend2 {
  width: 24px;
  height: 3px;
  background: #f7b500;
  border-radius: 2px;
}

.legend3 {
  width: 24px;
  height: 3px;
  background: #80c0ff;
  border-radius: 2px;
}

#echarts {
  width: 100%;
  height: 320px;
  z-index: 2;
  position: relative;
  top: -31px;
  // border: 1px solid red;
}

.close {
  position: absolute;
  right: 0;
  top: -30px;
  right: 0px;
}

.a {
  z-index: 99;
}

.b {
  z-index: -1;
}

//单位竖
.unitDiv {
  width: 22px;

  // position: relative;
  .d1 {
    transform: rotate(-90deg) translate(-20%, -50%);
    transform-origin: 0% 0%;
    position: absolute;
    left: 40px;
    // top: 50%;
    // left: 0;
    width: 150px;
  }
}

.width50 {
  width: 50px;
}

.width60 {
  width: 100px;
}

.bottom {
  overflow-y: auto;
}

::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}
.noDataMain{
  padding-top: 70px;
  margin-bottom: 58px;
}

.charts-leage-title{
color: #BDD7FF;
font-size: 14px;
font-weight: 400;
}

.charts-leage-value{
  color: #FFFFFF;font-size: 16px;font-weight: 500;
}
.title-align {
  margin-bottom: 7px;
}
.winStyle .bottom {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    background: #04153f;
    border-radius: 2px;
    border: 1px solid #0C3380;
}
</style>
